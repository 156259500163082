import React from 'react'
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge'
import Tooltip from '@mui/material/Tooltip'
import { formatter } from 'lib/utils'

export default function CapitalAdequacy({ data }) {
  const capitalAdequacy = data?.map((item) => ({
    project: item.project,
    value: `${Math.round(Number(item.capital_adequacy))}%`,
    shortage: item.shortage,
    due_date: item.earliest_due_date,
  }))

  return (
    <div className="grid grid-cols-[auto_auto]  w-fit h-52 items-center xl:-ml-10 2xl:-ml-0  2xl:-mt-0 ">
      {capitalAdequacy?.map((item) => (
        <Tooltip
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            },
          }}
          title={
            <div className="flex flex-col gap-y-2 text-sm">
              <span>{item.project} </span>
              <span>Shortage: ${formatter.format(item.shortage ?? 0)} </span>
              <span>Earliest due date: {item.due_date} </span>
            </div>
          }
        >
          <div key={item.value} className="relative">
            <Gauge
              width={110}
              height={110}
              value={Number(item.value.replace('%', ''))}
              cornerRadius="50%"
              text={item.value}
              innerRadius="68%"
              sx={(theme) => ({
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 16,
                  fontFamily: 'libre',
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: '#8fbd90',
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                  fill: '#efa57f',
                },
              })}
            />
          </div>
        </Tooltip>
      ))}
    </div>
  )
}
