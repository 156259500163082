import React from 'react'
import { IoChevronBackCircleOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import { toast } from 'sonner'
import Button from 'components/button'
import { TextField } from 'components/form/input'
import { editDebtTransaction } from 'lib/api/debt'
import { useAppContext } from 'context'
import { useDebtTransaction } from 'data/debt'
import { CircularProgress } from '@mui/material'
import { formatAmount } from 'lib/utils'

const validationSchema = yup.object().shape({
  project: yup.string().required('Enter project name'),
  transaction_type: yup.string().required('Enter transaction type'),
  transaction_date: yup
    .string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format (YYYY-MM-DD)')
    .required('A valid date is required'),
  loan_name: yup.string().required('Enter Loan name'),
  amount: yup.string().required('Enter Loan amount'),
  debt_outstanding: yup.string(),
})

export default function EditDebtTransaction() {
  const navigate = useNavigate()
  const { developer } = useAppContext()
  const { id } = useParams()

  const {
    data: debtTransaction,
    isLoading: isLoadingTransaction,
    isError: isErrorFetchingTransaction,
  } = useDebtTransaction({ developer: developer.split(' ')[0], id })

  if (isErrorFetchingTransaction) {
    toast.error('Error fetching debt transaction')
  }

  const formDefaultValues = {
    project: debtTransaction?.project ?? '',
    transaction_type: debtTransaction?.transaction_type ?? '',
    transaction_date: debtTransaction?.transaction_date ?? '',
    loan_name: debtTransaction?.loan_name ?? '',
    amount: debtTransaction?.amount ?? '',
    debt_outstanding: debtTransaction?.debt_outstanding ?? '',
  }

  return (
    <div className="relative w-[90%] bg-06 bg-opacity-80 shadow-[30px_30px_4px_0px_rgba(0,0,0,0.15)] h-[75vh] overflow-y-auto overflow-x-hidden rounded-[40px] px-16 py-8 justify-center justify-items-center">
      <div className="w-full text-center">
        <span className="bg-gradient-to-b font-libre from-[#9f9c5d] to-[rgba(193,155,22,1)] text-4xl text-transparent bg-clip-text">
          Debt Transaction
        </span>
        <span className="text-09 text-opacity-80 font-libre text-4xl"> Form</span>
      </div>

      {isLoadingTransaction ? (
        <div className=" grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-02" size={30} />
        </div>
      ) : (
        debtTransaction && (
          <Formik
            initialValues={formDefaultValues}
            onSubmit={async (values) => {
              try {
                values.amount = formatAmount(values.amount)
                values.debt_outstanding = formatAmount(values.debt_outstanding)
                const payload = {
                  ...values,
                  developer,
                  debt_commitment_letter: debtTransaction.debt_commitment_letter,
                }
                await editDebtTransaction({ developer: developer.split(' ')?.[0], payload, id })
                toast.success('Debt transaction edited successfully')
                navigate(
                  `/app-data/debt/transaction/history?cl-data-id=${debtTransaction.debt_commitment_letter}`
                )
              } catch (error) {
                toast.error(error.message ?? 'An error occurred while editing debt transaction')
              }
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => (
              <section>
                <button
                  className="top-8 absolute"
                  onClick={() =>
                    navigate(
                      `/app-data/debt/transaction/history?cl-data-id=${debtTransaction.debt_commitment_letter}`
                    )
                  }
                >
                  <IoChevronBackCircleOutline size={40} color="rgba(193,155,22,1)" />
                </button>
                <div className="mt-8">
                  <form className="flex justify-between pt-6 mb-16">
                    <div className="space-y-10">
                      <div className="gap-x-4 xl:gap-x-8 grid grid-cols-[150px_auto] xl:grid-cols-[220px_auto] items-center">
                        <TextField
                          className="w-[10rem] xl:w-[12rem]  2xl:w-[16rem] h-12"
                          label="Project"
                          type="text"
                          id="project"
                          name="project"
                          value={values.project}
                          error={touched.project && !!errors.project}
                          helperText={touched.project ? errors.project : ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className=" gap-x-4 xl:gap-x-8  grid grid-cols-[150px_auto] xl:grid-cols-[220px_auto]  items-center">
                        <label
                          className=" flex font-semibold text-02 font-libre align-baseline"
                          for="transaction_type"
                        >
                          Transaction Type
                        </label>
                        <select
                          className="flex  w-[10rem]  xl:w-[12rem] 2xl:w-[16rem] h-12   text-bca-grey-9 focus:border-bca-success-6 focus:outline-none text-sm border-4 font-libre border-[#42644C]  rounded-full text-center"
                          name="transaction_type"
                          id="transaction_type"
                          value={values.transaction_type}
                          error={touched.transaction_type && !!errors.transaction_type}
                          helperText={touched.transaction_type ? errors.transaction_type : ''}
                          onChange={handleChange}
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          <option value="Advance">Advance</option>
                          <option value="Discharge">Discharge</option>
                          <option value="Interest Type">Interest Type</option>
                        </select>
                      </div>
                      <div className="gap-x-4 xl:gap-x-8  grid grid-cols-[150px_auto] xl:grid-cols-[220px_auto] items-center">
                        <TextField
                          className="w-[10rem] xl:w-[12rem]  2xl:w-[16rem] h-12"
                          label="Transaction Date"
                          type="date"
                          id="transaction-date"
                          name="transaction_date"
                          value={values.transaction_date}
                          error={touched.transaction_date && !!errors.transaction_date}
                          helperText={touched.transaction_date ? errors.transaction_date : ''}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="space-y-10">
                      <div className="gap-x-4 xl:gap-x-8 grid grid-cols-[150px_auto] xl:grid-cols-[220px_auto] items-center">
                        <TextField
                          className="w-[10rem] xl:w-[12rem]  2xl:w-[16rem] h-12"
                          label="Loan Name"
                          type="text"
                          id="loan-name"
                          name="loan_name"
                          value={values.loan_name}
                          error={touched.loan_name && !!errors.loan_name}
                          helperText={touched.loan_name ? errors.loan_name : ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="gap-x-4 xl:gap-x-8 grid grid-cols-[150px_auto] xl:grid-cols-[220px_auto] items-center">
                        <TextField
                          className="w-[10rem] xl:w-[12rem]  2xl:w-[16rem] h-12"
                          label="Amount"
                          id="amount"
                          type="text"
                          name="amount"
                          value={values.amount}
                          onChange={handleChange}
                          error={touched.amount && !!errors.amount}
                          helperText={touched.amount ? errors.amount : ''}
                        />
                      </div>
                      <div className="gap-x-4 xl:gap-x-8 grid grid-cols-[150px_auto] xl:whitespace-nowrap xl:grid-cols-[220px_auto] items-center">
                        <TextField
                          className="w-[10rem] xl:w-[12rem] 2xl:w-[16rem] h-12"
                          label="Debt Outstanding"
                          type="text"
                          id="debt-outstanding"
                          name="debt_outstanding"
                          error={touched.debt_outstanding && !!errors.debt_outstanding}
                          helperText={touched.debt_outstanding ? errors.debt_outstanding : ''}
                          value={values.debt_outstanding}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="mt-24 mb-5 flex justify-center">
                  <Button
                    type="submit"
                    title="Save Transaction"
                    className="h-16 w-[271px]"
                    isSubmitting={isSubmitting}
                    onClick={handleSubmit}
                  />
                </div>
              </section>
            )}
          </Formik>
        )
      )}
    </div>
  )
}
