import { IoAddCircleOutline } from 'react-icons/io5'
import { React, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EditFileButton, UploadFileButton } from 'components/button/data-entry'
import { LuUpload } from 'react-icons/lu'
import { toast } from 'sonner'
import { BiSolidEdit } from 'react-icons/bi'
import Button from 'components/button'
import { uploadBankStatement, uploadLiquidityForcast } from 'lib/api/liquidity'
import { useDeveloperProjects, useFilesUploadStatus } from 'data/project'
import { CircularProgress } from '@mui/material'
import { useAppContext } from 'context'
import { useAllBankAccounts } from 'data/account'

export default function LiquidityDataForm() {
  const { developer: clientOrgName } = useAppContext()
  const {
    data: projects,
    isLoading,
    isError: isErrorFetchingProjects,
  } = useDeveloperProjects({ developer: clientOrgName.split(' ')?.[0] })

  const {
    data: filesUploadStatus,
    isLoading: isLoadingFileStatus,
    isError: isErrorFetchingUploadStatus,
    refetch: refetchFileUploadStatus,
  } = useFilesUploadStatus({
    developer: clientOrgName.split(' ')?.[0],
  })

  const accounts = useAllBankAccounts({ developerName: clientOrgName.split(' ')?.[0] })

  const navigate = useNavigate()
  const [fileDetails, setFileDetails] = useState([])
  const [statementFileDetails, setStatementFileDetails] = useState([])
  const [isSubmittingForecast, setIsSubmittingForecast] = useState(false)
  const [isSubmittingStatement, setIsSubmittingStatement] = useState(false)

  if (isErrorFetchingProjects) {
    toast.error('Error fetching developer projects')
  }

  if (isErrorFetchingUploadStatus) {
    toast.error('Error fetching files upload status')
  }

  const uploadForecast = async () => {
    for (let i = 0; i < fileDetails.length; i++) {
      try {
        setIsSubmittingForecast(true)
        const formData = new FormData()
        formData.append('file', fileDetails[i].file)
        formData.append('developer', clientOrgName)
        const result = await uploadLiquidityForcast({
          developerName: clientOrgName.split(' ')?.[0],
          payload: formData,
        })
        refetchFileUploadStatus()
        toast.success(`${fileDetails[i]?.developerName}: ${result.status}`)
      } catch (error) {
        toast.error(`${fileDetails[i]?.developerName} : ${error.message}` ?? 'An error occurred')
      } finally {
        setIsSubmittingForecast(false)
      }
    }
  }

  const uploadStatement = async () => {
    for (let i = 0; i < statementFileDetails.length; i++) {
      try {
        setIsSubmittingStatement(true)
        const formData = new FormData()
        formData.append('file', statementFileDetails[i].file)
        formData.append('developer', clientOrgName)
        const result = await uploadBankStatement({
          developerName: clientOrgName.split(' ')?.[0],
          payload: formData,
        })
        refetchFileUploadStatus()
        toast.success(`${statementFileDetails[i]?.fileTitle} bank statement : ${result.status}`)
      } catch (error) {
        toast.error(
          `${statementFileDetails[i]?.fileTitle} bank statement : ${error.message}` ??
            'An error occurred'
        )
      } finally {
        setIsSubmittingStatement(false)
      }
    }
  }

  const handleFileUpload = async (e, developerName) => {
    if (!e.target.files || !e.target.files[0]) {
      toast.error('No file selected')
      return
    }
    const resource = e.target.files[0]
    if (fileDetails.length) {
      if (fileDetails?.find((item) => item.developerName === developerName)) {
        return setFileDetails(
          fileDetails.map((item) =>
            item.developerName === developerName
              ? {
                  file: resource,
                  developerName: developerName,
                }
              : item
          )
        )
      }
    }
    setFileDetails([
      ...fileDetails,
      {
        file: resource,
        developerName: developerName,
      },
    ])
  }

  const handleStatementFileUpload = async (e, fileTitle) => {
    if (!e.target.files || !e.target.files[0]) {
      toast.error('No file selected')
      return
    }
    const resource = e.target.files[0]
    if (statementFileDetails.length) {
      if (statementFileDetails?.find((item) => item.fileTitle === fileTitle)) {
        return setStatementFileDetails(
          statementFileDetails.map((item) =>
            item.fileTitle === fileTitle
              ? {
                  file: resource,
                  fileTitle: fileTitle,
                }
              : item
          )
        )
      }
    }
    setStatementFileDetails([
      ...statementFileDetails,
      {
        file: resource,
        fileTitle: fileTitle,
      },
    ])
  }

  const getButtonTitle = (developerName, action, title) => {
    if (fileDetails.length && fileDetails?.find((item) => item.developerName === developerName)) {
      return fileDetails?.find((item) => item.developerName === developerName)?.file.name
    } else {
      return action === 'edit' ? title : `Upload ${developerName}`
    }
  }

  const getStatementButtonTitle = (fileTitle, action, title) => {
    if (
      statementFileDetails.length &&
      statementFileDetails?.find((item) => item.fileTitle === fileTitle)
    ) {
      return statementFileDetails?.find((item) => item.fileTitle === fileTitle)?.file.name
    } else {
      return action === 'edit' ? title : `Upload ${fileTitle} statement`
    }
  }

  return (
    <div className=" relative w-[90%] bg-06 bg-opacity-80 shadow-[30px_30px_4px_0px_rgba(0,0,0,0.15)] h-[75vh] overflow-y-hidden overflow-x-hidden rounded-[40px] px-16 py-8 justify-center justify-items-center">
      <div className="w-full text-center block">
        <div className="capitalHeading w-full text-center">
          <span className="bg-gradient-to-b font-libre from-[#9f9c5d] to-[rgba(193,155,22,1)] text-4xl text-transparent bg-clip-text  ">
            Liquidity
          </span>
          <span className="text-09 text-opacity-80 font-libre  text-4xl"> Data</span>
        </div>
        {!isLoading && !isErrorFetchingProjects && (
          <div className="capitalButton w-2/12 mt-4">
            <button onClick={() => navigate('/project-data-form')}>
              <IoAddCircleOutline size={55} color="rgba(193,155,22,1)" />
            </button>
          </div>
        )}
      </div>
      {isLoading || isLoadingFileStatus ? (
        <div className=" grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-02" size={30} />
        </div>
      ) : (
        projects &&
        (projects.length ? (
          <div className=" mt-6 overflow-y-auto justify-start pl-5 min-h-[80vh]  ">
            <div className="grid grid-cols-[160px_330px_5px_auto] gap-x-4 ">
              <div className="flex flex-col gap-y-9">
                {projects?.map((item) => (
                  <label
                    key={item.id}
                    className=" graph-title w-40 font-libre font-semibold text-sm text-02"
                  >
                    {item.project_name}
                  </label>
                ))}
              </div>
              <div className="flex flex-col gap-y-6">
                {projects?.map((item) =>
                  filesUploadStatus?.find((status) => status.project_name === item.project_name)
                    ?.forecasts_exists ? (
                    <div key={item.id} className="relative">
                      <EditFileButton
                        disabled={isSubmittingForecast}
                        icon={<BiSolidEdit color="white" size={22} />}
                        title={getButtonTitle(
                          `${item.project_name.split(' ')?.[0]} forecast`,
                          'edit',
                          `${item.project_name.split(' ')?.[0]} forecast updated`
                        )}
                        className="w-[320px] flex justify-evenly"
                      />
                      <input
                        type="file"
                        name="file"
                        accept=" .csv"
                        disabled={isSubmittingForecast}
                        onChange={(e) =>
                          handleFileUpload(e, `${item.project_name.split(' ')?.[0]} forecast`)
                        }
                        className="absolute cursor-pointer inset-0 opacity-0"
                      />
                    </div>
                  ) : (
                    <div key={item.id} className="relative">
                      <UploadFileButton
                        disabled={isSubmittingForecast}
                        title={getButtonTitle(`${item.project_name.split(' ')?.[0]} forecast`)}
                        className="w-[320px] cursor-pointer"
                        icon={<LuUpload color="white" size={22} />}
                      />
                      <input
                        type="file"
                        name="file"
                        accept=" .csv"
                        disabled={isSubmittingForecast}
                        onChange={(e) =>
                          handleFileUpload(e, `${item.project_name.split(' ')?.[0]} forecast`)
                        }
                        className="absolute cursor-pointer inset-0 opacity-0"
                      />
                    </div>
                  )
                )}
              </div>
              <div className=" w-[2.5px] bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
              <div className="flex flex-col gap-y-6">
                {projects?.map((item) => (
                  <div className="flex flex-row gap-x-4">
                    {accounts?.data
                      ?.filter((account) => account.project === item.project_name)
                      .map((account) =>
                        filesUploadStatus?.find(
                          (status) => status.project_name === item.project_name
                        )?.bank_transaction_exists &&
                        filesUploadStatus?.find((status) =>
                          status.bank_account_names?.includes(account.account_ending)
                        ) ? (
                          <div key={item.id} className="relative">
                            <EditFileButton
                              disabled={isSubmittingStatement}
                              icon={<BiSolidEdit color="white" size={22} />}
                              title={getStatementButtonTitle(
                                account.account_ending,
                                'edit',
                                `${account.account_ending} statement updated`
                              )}
                              className="w-[260px] flex justify-evenly"
                            />

                            <input
                              type="file"
                              name="upload-statement"
                              accept=" .csv"
                              disabled={isSubmittingStatement}
                              onChange={(e) => handleStatementFileUpload(e, account.account_ending)}
                              className="absolute inset-0 opacity-0"
                            />
                          </div>
                        ) : (
                          <div key={item.id} className="relative">
                            <UploadFileButton
                              title={getStatementButtonTitle(account.account_ending)}
                              disabled={isSubmittingStatement}
                              className="w-[260px] cursor-pointer"
                              icon={<LuUpload color="white" size={22} />}
                            />
                            <input
                              type="file"
                              name="upload-statement"
                              accept=" .csv"
                              disabled={isSubmittingStatement}
                              onChange={(e) => handleStatementFileUpload(e, account.account_ending)}
                              className="absolute inset-0 opacity-0"
                            />
                          </div>
                        )
                      )}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full mt-16 xl:mt-28 grid absolute right-10  gap-x-3 justify-end grid-cols-[auto_auto] pr-8">
              <Button
                title="Upload forecast"
                onClick={uploadForecast}
                isSubmitting={isSubmittingForecast}
                className="h-16 w-[180px]"
              />
              <Button
                title="Upload statement"
                onClick={uploadStatement}
                isSubmitting={isSubmittingStatement}
                className="h-16 w-[180px]"
              />
            </div>
          </div>
        ) : (
          <p className=" mt-40 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
            No active project
          </p>
        ))
      )}
    </div>
  )
}
