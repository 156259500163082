import { React, useEffect, useState } from 'react'
import { useAppContext } from 'context'
import {
  useDeveloperProjects,
  useFilesUploadStatus,
  useFirmwideFilesUploadStatus,
  useOpeningBalanceStatus,
} from 'data/project'
import { CircularProgress } from '@mui/material'
import { toast } from 'sonner'
import { format } from 'date-fns'
import { useAllBankAccounts } from 'data/account'
import { getFileInfo, getMonthlyFileInfo, getNextDayDate } from 'lib/utils'
import { twMerge } from 'tailwind-merge'
import Button from 'components/button'
import { usePDF } from 'react-to-pdf'
import {
  useDebtCLStatus,
  useDebtCls,
  useUpcomingFinancing,
  useUpcomingFinancingStatus,
} from 'data/debt'
import { useProfile } from 'data/profile'

const TableRow = ({ project, data, point, frequency, dueDate, status, download }) => {
  return (
    <tr className={`${download ? '' : 'odd:bg-[#c9cd77] '} `}>
      <td className="py-2.5 text-sm pl-6 table-cell font-libre  items-center justify-start tracking-wider text-02 rounded-l-lg ">
        {project}
      </td>
      <td className="text-sm font-libre tracking-wider text-02">{data}</td>
      <td className="text-sm font-libre tracking-wider  text-02">{point}</td>
      <td className="text-sm font-libre tracking-wider  text-02">{frequency}</td>
      <td className="text-sm font-libre tracking-wider  text-02">{dueDate}</td>
      <td className="text-sm font-libre tracking-wider rounded-r-lg  text-02">
        <span
          className={twMerge(
            'rounded-full w-fit grid place-items-center  py-1 px-2 xl:px-3 text-xs xl:text-[13px]',
            statusColor(status?.toLocaleLowerCase(), download)
          )}
        >
          {status}
        </span>
      </td>
    </tr>
  )
}

const statusColor = (status, download) => {
  switch (status) {
    case 'done':
      return `${download ? '' : 'bg-[#C6EFCD] '} text-02`
    case 'past due':
      return `${download ? '' : 'bg-[#FFC7CD]  '} text-[#9E040A]`
    case 'outstanding':
      return `${download ? '' : 'bg-[#FFEB9C]'} text-[#9C5700]`
    default:
      return `${download ? '' : 'bg-[#C6EFCD] '} text-02`
  }
}

export default function Checklist() {
  const { developer } = useAppContext()
  const profile = useProfile()

  const { toPDF, targetRef } = usePDF({ filename: `${developer} Treasury Data Checklist.pdf` })
  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorFetchingProjects,
  } = useDeveloperProjects({ developer: developer.split(' ')?.[0] })
  const bankAccountBalance = useOpeningBalanceStatus({ developer: developer.split(' ')[0] })

  console.log(bankAccountBalance?.data)
  const financing = useUpcomingFinancing({ developer: developer.split(' ')[0] })
  const debtCLStatus = useDebtCLStatus({ developer: developer.split(' ')[0] })
  const upcomingFinancingStatus = useUpcomingFinancingStatus({ developer: developer.split(' ')[0] })

  const accounts = useAllBankAccounts({ developerName: developer.split(' ')?.[0] })

  const debtCls = useDebtCls({ developer: developer.split(' ')?.[0] })

  if (isErrorFetchingProjects) {
    toast.error('Error fetching developer projects')
  }

  if (bankAccountBalance.isError) {
    toast.error('Error fetching bank accounts opening balance status')
  }

  if (profile.isError) {
    toast.error('Error fetching profile')
  }

  if (debtCls.isError) {
    toast.error('Error fetching debt commitment letters')
  }

  if (accounts.isError) {
    toast.error('Error fetching developer bank accounts')
  }

  const isFileUploaded = (projectName, condition) => {
    return filesUploadStatus?.find((files) => files.project_name === projectName)[condition]
      ? true
      : false
  }
  const [developerCreatedDate, setDate] = useState('')

  useEffect(() => {
    if (profile?.data) {
      setDate(
        profile?.data?.payload?.developers?.find(
          (item) => item.developer.organizationName === developer
        )?.developer?.dateCreated
      )
    }
  }, [profile, developer])

  const isBankFileUploaded = (projectName, accountNumber) => {
    return filesUploadStatus?.find((files) => files.project_name === projectName)
      ?.bank_transaction_exists &&
      filesUploadStatus?.find((files) => files.bank_account_names?.includes(accountNumber))
      ? true
      : false
  }

  const {
    data: filesUploadStatus,
    isLoading: isLoadingFileStatus,
    isError: isErrorFetchingUploadStatus,
  } = useFilesUploadStatus({
    developer: developer.split(' ')?.[0],
  })

  if (isErrorFetchingUploadStatus) {
    toast.error('Error fetching file upload status')
  }

  const firmwideFilesStatus = useFirmwideFilesUploadStatus({
    developer: developer.split(' ')?.[0],
  })

  return (
    <>
      <div className="relative w-[100%] bg-06 bg-opacity-80 shadow-[30px_30px_4px_0px_rgba(0,0,0,0.15)]  h-[80vh]  overflow-y-hidden overflow-x-hidden rounded-[40px] py-8 px-4 justify-center justify-items-center">
        <div className="w-full justify-center relative  flex ">
          <div className="capitalHeading">
            <span className="text-09 text-opacity-80 font-libre  text-xl xl:text-3xl">
              {developer.split(' ')?.[0]}
            </span>
            <span className="text-09 text-opacity-80 font-libre  text-xl xl:text-3xl">
              {' '}
              {developer.split(' ')?.[1]} Checklist:
            </span>
            <span className="text-09 text-opacity-80 font-libre  text-xl xl:text-3xl">
              {' '}
              {format(new Date(), 'EEEE MMMM dd, yyyy')}
            </span>
          </div>
          {isLoadingProjects ||
          debtCls.isLoading ||
          financing.isLoading ||
          accounts.isLoading ||
          isLoadingFileStatus ||
          !projects?.length ? null : (
            <div className=" absolute right-12  ">
              <Button
                onClick={() => toPDF()}
                type="submit"
                title="Download"
                className="h-9 text-xs w-[90px]"
              />
            </div>
          )}
        </div>
        {isLoadingProjects ||
        debtCls.isLoading ||
        accounts.isLoading ||
        isLoadingFileStatus ||
        financing.isLoading ||
        bankAccountBalance.isLoading ||
        upcomingFinancingStatus.isLoading ? (
          <div className=" grid place-items-center mt-36">
            <CircularProgress color="inherit" className="text-02" size={30} />
          </div>
        ) : (
          projects &&
          (projects.length ? (
            <div className="  max-h-[70vh] mt-4 overflow-y-auto ml-10 flex justify-center  ">
              <table className=" w-[95%] align-middle ">
                <thead className="font-libre font-semibold text-02">
                  <tr className=" graph-title  ">
                    <th className=" w-[200px] pl-6 py-5 text-left font-semibold font-libre   tracking-wider text-02">
                      Project
                    </th>
                    <th className=" w-[200px] pl-4 text-left font-semibold font-libre   tracking-wider text-02">
                      Data Group
                    </th>
                    <th className=" w-[250px] text-left  font-semibold font-libre   tracking-wider text-02">
                      Data Point
                    </th>
                    <th className=" w-[150px]  text-left  font-semibold font-libre   tracking-wider text-02">
                      Frequency
                    </th>
                    <th className=" w-[150px] text-left  font-semibold fontlibre   tracking-wider text-02">
                      Due date
                    </th>
                    <th className=" w-[150px] text-left  font-semibold fontlibre   tracking-wider text-02">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {projects.map((project) => (
                    <>
                      <TableRow
                        project={project.project_name}
                        data="Liquidity Data"
                        point="Forecast"
                        frequency="Weekly"
                        dueDate={
                          getFileInfo(
                            getNextDayDate('monday'),
                            project.date_created,
                            isFileUploaded(project.project_name, 'forecasts_exists')
                          ).date
                        }
                        status={
                          isFileUploaded(project.project_name, 'forecasts_exists')
                            ? getFileInfo(getNextDayDate('monday'), project.date_created, true)
                                .status
                            : getFileInfo(getNextDayDate('monday'), project.date_created, false)
                                .status
                        }
                      />
                      {accounts?.data?.length &&
                        accounts?.data
                          ?.filter((item) => item.project === project.project_name)
                          .map((account) => (
                            <TableRow
                              project={project.project_name}
                              data="Liquidity Data"
                              point={`${account.account_ending} statement`}
                              frequency="Weekly"
                              dueDate={
                                getFileInfo(
                                  getNextDayDate('monday'),
                                  account.date_created,
                                  isBankFileUploaded(project.project_name, account.account_ending)
                                ).date
                              }
                              status={
                                isBankFileUploaded(project.project_name, account.account_ending)
                                  ? getFileInfo(
                                      getNextDayDate('monday'),
                                      account.date_created,
                                      true
                                    ).status
                                  : getFileInfo(
                                      getNextDayDate('monday'),
                                      account.date_created,
                                      false
                                    ).status
                              }
                            />
                          ))}
                    </>
                  ))}
                  {projects.map((project) =>
                    debtCls?.data?.length
                      ? debtCls.data
                          .filter((item) => item.project === project.project_name)
                          .map((letter, index) => (
                            <TableRow
                              key={index}
                              project={project.project_name}
                              data="Debt Data"
                              point={`${format(new Date(letter.interest_adjustment_date), 'MM-yy')} ${letter.lender}`}
                              frequency="Monthly"
                              dueDate={
                                getMonthlyFileInfo(
                                  letter.date_created,
                                  debtCLStatus?.data?.payload?.find(
                                    (item) =>
                                      item.project === project &&
                                      item.debt_commitment_letter === letter.id
                                  )?.debt_transaction_exists
                                    ? true
                                    : false
                                ).date
                              }
                              status={
                                debtCLStatus.data?.payload?.find(
                                  (item) =>
                                    item.project === project &&
                                    item.debt_commitment_letter === letter.id
                                )?.debt_transaction_exists
                                  ? getMonthlyFileInfo(letter.date_created, true).status
                                  : getMonthlyFileInfo(letter.date_created, false).status
                              }
                            />
                          ))
                      : null
                  )}
                  <>
                    {developerCreatedDate && (
                      <TableRow
                        project="Firmwide"
                        data="Firmwide Data"
                        point="Overhead Forecast"
                        frequency="Weekly"
                        dueDate={
                          getFileInfo(
                            getNextDayDate('monday'),
                            developerCreatedDate,
                            firmwideFilesStatus?.data?.payload?.forecasts_exists ? true : false
                          ).date
                        }
                        status={
                          firmwideFilesStatus?.data?.payload?.forecasts_exists
                            ? getFileInfo(getNextDayDate('monday'), developerCreatedDate, true)
                                .status
                            : getFileInfo(getNextDayDate('monday'), developerCreatedDate, false)
                                .status
                        }
                      />
                    )}

                    {accounts?.data?.length &&
                      accounts?.data
                        ?.filter((item) => item.project === 'Firmwide')
                        .map((account) => (
                          <TableRow
                            project="Firmwide"
                            data="Firmwide Data"
                            point={`${account.account_ending} statement`}
                            frequency="Weekly"
                            dueDate={
                              getFileInfo(
                                getNextDayDate('monday'),
                                account.date_created,
                                firmwideFilesStatus?.data?.payload?.bank_transaction_exists &&
                                  filesUploadStatus?.payload?.bank_account_names?.includes(
                                    account.account_ending
                                  )
                                  ? true
                                  : false
                              ).date
                            }
                            status={
                              firmwideFilesStatus?.data?.payload?.bank_transaction_exists &&
                              filesUploadStatus?.payload?.bank_account_names?.includes(
                                account.account_ending
                              )
                                ? getFileInfo(getNextDayDate('monday'), account.date_created, true)
                                    .status
                                : getFileInfo(getNextDayDate('monday'), account.date_created, false)
                                    .status
                            }
                          />
                        ))}
                  </>
                  {projects.map((project) => (
                    <>
                      <TableRow
                        project={project.project_name}
                        data="Activity and Sales Tables"
                        point="Activity Table"
                        frequency="Monthly"
                        dueDate={
                          getMonthlyFileInfo(
                            project.date_created,
                            isFileUploaded(project.project_name, 'activity_exists')
                          ).date
                        }
                        status={
                          isFileUploaded(project.project_name, 'activity_exists')
                            ? getMonthlyFileInfo(project.date_created, true).status
                            : getMonthlyFileInfo(project.date_created, false).status
                        }
                      />
                      <TableRow
                        project={project.project_name}
                        data="Activity and Sales Tables"
                        point="Sales Summary"
                        frequency="Monthly"
                        dueDate={
                          getMonthlyFileInfo(
                            project.date_created,
                            isFileUploaded(project.project_name, 'sales_summary_exists')
                          ).date
                        }
                        status={
                          isFileUploaded(project.project_name, 'sales_summary_exists')
                            ? getMonthlyFileInfo(project.date_created, true).status
                            : getMonthlyFileInfo(project.date_created, false).status
                        }
                      />
                      <TableRow
                        project={project.project_name}
                        data="Financing Schedule"
                        point="Financing Schedule"
                        frequency="Weekly"
                        dueDate={
                          getFileInfo(
                            getNextDayDate('monday'),
                            project.date_created,
                            isFileUploaded(project.project_name, 'financing_schedule_exists')
                          ).date
                        }
                        status={
                          isFileUploaded(project.project_name, 'financing_schedule_exists')
                            ? getFileInfo(getNextDayDate('monday'), project.date_created, true)
                                .status
                            : getFileInfo(getNextDayDate('monday'), project.date_created, false)
                                .status
                        }
                      />
                    </>
                  ))}
                  {financing.data?.map((item) => (
                    <TableRow
                      project={item.project}
                      data="Upcoming Financings"
                      point={item.purpose}
                      frequency="Monthly"
                      dueDate={
                        getMonthlyFileInfo(
                          item.date_created,
                          upcomingFinancingStatus.data?.payload?.upcoming_financings?.find(
                            (financing) => financing.id === item.id
                          )?.is_changed_last_month
                            ? true
                            : false
                        ).date
                      }
                      status={
                        upcomingFinancingStatus.data?.payload?.upcoming_financings?.find(
                          (financing) => financing.id === item.id
                        )?.is_changed_last_month
                          ? getMonthlyFileInfo(item.date_created, true).status
                          : getMonthlyFileInfo(item.date_created, false).status
                      }
                    />
                  ))}
                  {bankAccountBalance.data?.payload?.map((item) => (
                    <TableRow
                      project={item.project}
                      data="Opening Balance"
                      point={`${item.account_ending} statement`}
                      frequency="Weekly"
                      dueDate={
                        getFileInfo(
                          getNextDayDate('monday'),
                          item.date_created,
                          item.is_updated ? true : false
                        ).date
                      }
                      status={
                        getFileInfo(
                          getNextDayDate('monday'),
                          item.date_created,
                          item.is_updated ? true : false
                        ).status
                      }
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className=" mt-40 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
              No active project
            </p>
          ))
        )}
      </div>

      {/* downloaded file */}
      <div
        ref={targetRef}
        className="relative mt-[1000%]  w-[100%] bg-06 bg-opacity-80 shadow-[30px_30px_4px_0px_rgba(0,0,0,0.15)]  rounded-[40px] py-8 px-4 justify-center justify-items-center"
      >
        <div className="w-full text-center relative  ">
          <div className="capitalHeading">
            <span className="text-09 text-opacity-80 font-libre  text-xl xl:text-3xl">
              {developer.split(' ')?.[0]}
            </span>
            <span className="text-09 text-opacity-80 font-libre  text-xl xl:text-3xl">
              {' '}
              {developer.split(' ')?.[1]} Checklist:
            </span>
            <span className="text-09 text-opacity-80 font-libre  text-xl xl:text-3xl">
              {' '}
              {format(new Date(), 'EEEE MMMM dd, yyyy')}
            </span>
          </div>
        </div>
        {projects && (
          <div className="  mt-4 overflow-y-auto ml-10 flex justify-center  ">
            <table className=" w-[95%] align-middle ">
              <thead className="font-libre font-semibold text-02">
                <tr>
                  <th className=" w-[200px] pl-6 py-5 text-left font-semibold font-libre   tracking-wider text-02">
                    Project
                  </th>
                  <th className=" w-[200px] pl-4 text-left font-semibold font-libre   tracking-wider text-02">
                    Data Group
                  </th>
                  <th className=" w-[250px] text-left  font-semibold font-libre   tracking-wider text-02">
                    Data Point
                  </th>
                  <th className=" w-[150px]  text-left  font-semibold font-libre   tracking-wider text-02">
                    Frequency
                  </th>
                  <th className=" w-[150px] text-left  font-semibold fontlibre   tracking-wider text-02">
                    Due date
                  </th>
                  <th className=" w-[150px] text-left  font-semibold fontlibre   tracking-wider text-02">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {projects.map((project) => (
                  <>
                    <TableRow
                      download={true}
                      project={project.project_name}
                      data="Liquidity Data"
                      point="Forecast"
                      frequency="Weekly"
                      dueDate={
                        getFileInfo(
                          getNextDayDate('monday'),
                          project.date_created,
                          isFileUploaded(project.project_name, 'forecasts_exists')
                        ).date
                      }
                      status={
                        isFileUploaded(project.project_name, 'forecasts_exists')
                          ? getFileInfo(getNextDayDate('monday'), project.date_created, true).status
                          : getFileInfo(getNextDayDate('monday'), project.date_created, false)
                              .status
                      }
                    />
                    {accounts?.data?.length &&
                      accounts?.data
                        ?.filter((item) => item.project === project.project_name)
                        .map((account) => (
                          <TableRow
                            download={true}
                            project={project.project_name}
                            data="Liquidity Data"
                            point={`${account.account_ending} statement`}
                            frequency="Weekly"
                            dueDate={
                              getFileInfo(
                                getNextDayDate('monday'),
                                account.date_created,
                                isBankFileUploaded(project.project_name, account.account_ending)
                              ).date
                            }
                            status={
                              isBankFileUploaded(project.project_name, account.account_ending)
                                ? getFileInfo(getNextDayDate('monday'), account.date_created, true)
                                    .status
                                : getFileInfo(getNextDayDate('monday'), account.date_created, false)
                                    .status
                            }
                          />
                        ))}
                  </>
                ))}
                {projects.map((project) =>
                  debtCls?.data?.length
                    ? debtCls.data
                        .filter((item) => item.project === project.project_name)
                        .map((letter, index) => (
                          <TableRow
                            download={true}
                            key={index}
                            project={project.project_name}
                            data="Debt Data"
                            point={`${format(new Date(letter.interest_adjustment_date), 'MM-yy')} ${letter.lender}`}
                            frequency="Monthly"
                            dueDate={
                              getMonthlyFileInfo(
                                letter.date_created,
                                debtCLStatus?.data?.payload?.find(
                                  (item) =>
                                    item.project === project &&
                                    item.debt_commitment_letter === letter.id
                                )?.debt_transaction_exists
                                  ? true
                                  : false
                              ).date
                            }
                            status={
                              debtCLStatus.data?.payload?.find(
                                (item) =>
                                  item.project === project &&
                                  item.debt_commitment_letter === letter.id
                              )?.debt_transaction_exists
                                ? getMonthlyFileInfo(letter.date_created, true).status
                                : getMonthlyFileInfo(letter.date_created, false).status
                            }
                          />
                        ))
                    : null
                )}
                <>
                  {developerCreatedDate && (
                    <TableRow
                      download={true}
                      project="Firmwide"
                      data="Firmwide Data"
                      point="Overhead Forecast"
                      frequency="Weekly"
                      dueDate={
                        getFileInfo(
                          getNextDayDate('monday'),
                          developerCreatedDate,
                          firmwideFilesStatus?.data?.payload?.forecasts_exists ? true : false
                        ).date
                      }
                      status={
                        firmwideFilesStatus?.data?.payload?.forecasts_exists
                          ? getFileInfo(getNextDayDate('monday'), developerCreatedDate, true).status
                          : getFileInfo(getNextDayDate('monday'), developerCreatedDate, false)
                              .status
                      }
                    />
                  )}

                  {accounts?.data?.length &&
                    accounts?.data
                      ?.filter((item) => item.project === 'Firmwide')
                      .map((account) => (
                        <TableRow
                          download={true}
                          project="Firmwide"
                          data="Firmwide Data"
                          point={`${account.account_ending} statement`}
                          frequency="Weekly"
                          dueDate={
                            getFileInfo(
                              getNextDayDate('monday'),
                              account.date_created,
                              firmwideFilesStatus?.data?.payload?.bank_transaction_exists &&
                                filesUploadStatus?.payload?.bank_account_names?.includes(
                                  account.account_ending
                                )
                                ? true
                                : false
                            ).date
                          }
                          status={
                            firmwideFilesStatus?.data?.payload?.bank_transaction_exists &&
                            filesUploadStatus?.payload?.bank_account_names?.includes(
                              account.account_ending
                            )
                              ? getFileInfo(getNextDayDate('monday'), account.date_created, true)
                                  .status
                              : getFileInfo(getNextDayDate('monday'), account.date_created, false)
                                  .status
                          }
                        />
                      ))}
                </>
                {projects.map((project) => (
                  <>
                    <TableRow
                      download={true}
                      project={project.project_name}
                      data="Activity and Sales Tables"
                      point="Activity Table"
                      frequency="Monthly"
                      dueDate={
                        getMonthlyFileInfo(
                          project.date_created,
                          isFileUploaded(project.project_name, 'activity_exists')
                        ).date
                      }
                      status={
                        isFileUploaded(project.project_name, 'activity_exists')
                          ? getMonthlyFileInfo(project.date_created, true).status
                          : getMonthlyFileInfo(project.date_created, false).status
                      }
                    />
                    <TableRow
                      download={true}
                      project={project.project_name}
                      data="Activity and Sales Tables"
                      point="Sales Summary"
                      frequency="Monthly"
                      dueDate={
                        getMonthlyFileInfo(
                          project.date_created,
                          isFileUploaded(project.project_name, 'sales_summary_exists')
                        ).date
                      }
                      status={
                        isFileUploaded(project.project_name, 'sales_summary_exists')
                          ? getMonthlyFileInfo(project.date_created, true).status
                          : getMonthlyFileInfo(project.date_created, false).status
                      }
                    />
                    <TableRow
                      project={project.project_name}
                      download={true}
                      data="Financing Schedule"
                      point="Financing Schedule"
                      frequency="Weekly"
                      dueDate={
                        getFileInfo(
                          getNextDayDate('monday'),
                          project.date_created,
                          isFileUploaded(project.project_name, 'financing_schedule_exists')
                        ).date
                      }
                      status={
                        isFileUploaded(project.project_name, 'financing_schedule_exists')
                          ? getFileInfo(getNextDayDate('monday'), project.date_created, true).status
                          : getFileInfo(getNextDayDate('monday'), project.date_created, false)
                              .status
                      }
                    />
                  </>
                ))}
                {financing.data?.map((item) => (
                  <TableRow
                    download={true}
                    project={item.project}
                    data="Upcoming Financings"
                    point={item.purpose}
                    frequency="Monthly"
                    dueDate={
                      getMonthlyFileInfo(
                        item.date_created,
                        upcomingFinancingStatus.data?.payload?.upcoming_financings?.find(
                          (financing) => financing.id === item.id
                        )?.is_changed_last_month
                          ? true
                          : false
                      ).date
                    }
                    status={
                      upcomingFinancingStatus.data?.payload?.upcoming_financings?.find(
                        (financing) => financing.id === item.id
                      )?.is_changed_last_month
                        ? getMonthlyFileInfo(item.date_created, true).status
                        : getMonthlyFileInfo(item.date_created, false).status
                    }
                  />
                ))}
                {bankAccountBalance.data?.payload?.map((item) => (
                  <TableRow
                    project={item.project}
                    data="Opening Balance"
                    point={`${item.account_ending} statement`}
                    frequency="Weekly"
                    dueDate={
                      getFileInfo(
                        getNextDayDate('monday'),
                        item.date_created,
                        item.is_updated ? true : false
                      ).date
                    }
                    status={
                      getFileInfo(
                        getNextDayDate('monday'),
                        item.date_created,
                        item.is_updated ? true : false
                      ).status
                    }
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  )
}
