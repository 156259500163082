import ReactEcharts from 'echarts-for-react'
import { getCurrentWeekDates, formatter } from 'lib/utils'

export default function MoneyMarketChart({ data }) {
  const [start, end] = getCurrentWeekDates()

  const weekMarketData =
    start && end
      ? data.filter(
          (item) => new Date(item.date) >= new Date(start) && new Date(item.date) < new Date(end)
        )
      : data

  const dataset = weekMarketData.map((item) => ({
    name: item.cash_direction?.[0].toUpperCase() + item.cash_direction?.slice(1),
    date: item.date,
    forecast_type: item.forecast_type?.[0].toUpperCase() + item.forecast_type?.slice(1),
    amount: item.amount,
  }))

  const barColor = (val) => {
    if (val < 0) {
      return '#e9ca54'
    } else if (val > 0) {
      return '#e79c52'
    } else {
      return null
    }
  }

  const option = {
    tooltip: {
      trigger: 'axis',
      borderColor: '#f9eeca',
      backgroundColor: '#f9eeca',
      textStyle: {
        color: '#42644c ',
        fontFamily: 'libre',
      },
      formatter: (params) => {
        return `Date: ${params?.[0]?.data?.date} <br /> Cash direction: ${params?.[0]?.name} <br /> Forecast type: ${params?.[0]?.data?.forecast_type}  <br /> Amount: ${params?.[0]?.data?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[0]?.data?.value))}`
      },
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#42644c',
            width: 3,
            cap: 'butt',
          },
        },
        splitLine: {
          show: false,
        },
        data: dataset.map((item) => item.name),
        axisTick: { show: false },
        axisLabel: { show: false },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: { show: false },
        axisTick: { show: false },
      },
    ],
    series: [
      {
        type: 'bar',
        data: dataset.map((item) => ({
          value: item.amount,
          date: item.date,
          forecast_type: item.forecast_type,
          itemStyle: {
            color: barColor(item.amount),
          },
        })),
      },
    ],
  }

  return <ReactEcharts option={option} className="w-[150px] 2xl:w-[250px]" />
}
