import CustomSlider from './Slider'
import CustomSwitch from './Switch'
import { useAppContext } from 'context'
import { saveRevenueVariation } from 'lib/api/capital'
import { toast } from 'sonner'

export default function RevenueToComplete({ setIsLoadingRoi, next, checked, onChange }) {
  const { developer } = useAppContext()

  const handleChange = async (value) => {
    try {
      setIsLoadingRoi(true)
      const response = await saveRevenueVariation({
        developerName: developer.split(' ')?.[0],
        payload: {
          value_change: Math.abs(value / 100),
          status: value < 0 ? 'decrement' : 'increment',
        },
      })
      next({
        roiData: response.payload.annualized_roi,
      })
    } catch (error) {
      toast.error(error.message ?? 'An error occurred while saving revenue variation')
    } finally {
      setIsLoadingRoi(false)
    }
  }

  return (
    <section>
      <div className="grid grid-cols-[90px_auto_auto]  items-center">
        <p className="graph-title text-02 font-semibold  text-xs font-libre">Revenue to complete</p>
        <CustomSlider disabled={checked === false} handleChange={(value) => handleChange(value)} />
        <CustomSwitch checked={checked} onChange={onChange} />
      </div>
    </section>
  )
}
