import ReactEcharts from 'echarts-for-react'
import { format } from 'date-fns'
import { formatter } from 'lib/utils'

export default function CashBalanceChart({ data, date }) {
  const dataset = data.map((item) => ({
    name: item.project,
    amount: item.net_cash_flow,
    opening_balance: item.opening_balance,
    closing_balance: item.closing_balance,
  }))

  const valuesArray = Object.values(dataset).map((item) => item.amount)

  // make array of bar colors, highlighting max/min
  const minVal = Math.min(...valuesArray)
  const maxVal = Math.max(...valuesArray)

  const barColor = (val) => {
    if (val < 0) {
      return val > minVal ? 'rgba(234,90,52,0.7)' : 'rgba(178,53,20,0.7)'
    } else if (val > 0) {
      return val < maxVal / 2 ? 'rgba(99,169,120,0.7)' : 'rgba(66,100,76,0.7)'
    } else {
      return null // non-max and non-min values take default color of series
    }
  }

  const option = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#f9eeca',
      textStyle: {
        color: '#42644c ',
        fontFamily: 'libre',
      },
      formatter: (params) => {
        return `Project: ${params?.[0]?.name} <br />  Opening balance: ${params?.[0]?.data?.opening_balance < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[0]?.data?.opening_balance))} <br /> Net cashflow: ${params?.[0]?.data?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[0]?.data?.value))}  <br /> Closing balance: ${params?.[0]?.data?.closing_balance < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[0]?.data?.closing_balance))}`
      },
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'value',
        name: `From ${format(new Date(date), 'MMM dd')}`,
        nameLocation: 'middle',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#42644c',
            width: 3,
            cap: 'butt',
          },
        },
        splitLine: {
          show: false,
        },
        axisLabel: { show: false },
        nameTextStyle: {
          fontSize: 16,
          fontFamily: 'libre',
        },
      },
    ],
    yAxis: [
      {
        type: 'category',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#42644c',
            width: 3,
            cap: 'butt',
          },
        },
        axisLabel: { show: false },
        axisTick: { show: false },
        data: dataset.map((item) => item.name),
      },
    ],
    series: [
      {
        type: 'bar',
        data: dataset.map((item) => ({
          value: item.amount,
          opening_balance: item.opening_balance,
          closing_balance: item.closing_balance,
          itemStyle: {
            color: barColor(item.amount),
          },
        })),
      },
    ],
  }

  return (
    <ReactEcharts option={option} className="w-[250px] h-[250px] 2xl:w-[300px] 2xl:h-[350px]" />
  )
}
