import Button from 'components/button'
import { IoChevronBackCircleOutline, IoAddCircleOutline } from 'react-icons/io5'
import React, { useState } from 'react'
import { Select, TextField } from 'components/form/input'
import { Formik } from 'formik'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppContext } from 'context'
import { toast } from 'sonner'
import { formatAmount } from 'lib/utils'
import { saveCapitalSources } from 'lib/api/capital'

export default function AddCapitalSources() {
  const navigate = useNavigate()
  const { developer } = useAppContext()
  const [searchParams] = useSearchParams()
  const projectName = searchParams.get('project').replace(/-/g, ' ')
  const [rows, setRows] = useState([
    {
      capital_source: '',
      amount: '',
      start_date: '',
      end_date: '',
      developer,
      project: projectName,
    },
  ])

  const addRow = () => {
    setRows([
      ...rows,
      {
        capital_source: '',
        amount: '',
        start_date: '',
        end_date: '',
        developer,
        project: projectName,
      },
    ])
  }

  const handleRowChange = (index, field, value) => {
    const newRows = [...rows]
    newRows[index][field] = value
    setRows(newRows)
  }

  const handleSubmit = async () => {
    for (const row of rows) {
      try {
        const payload = {
          capital_source: row.capital_source,
          amount: formatAmount(row.amount),
          start_date: row.start_date,
          end_date: row.end_date,
          developer,
          project: projectName,
        }
        await saveCapitalSources({ developerName: developer.split(' ')?.[0], payload })
        toast.success('Capital Sources successfully saved')
        navigate(`/capital-sources?project=${searchParams.get('project')}`)
      } catch (error) {
        toast.error(error.message ?? 'An error occurred while saving capital sources')
      }
    }
  }

  const formDefaultValues = {
    rows,
  }
  return (
    <div className="relative w-[80%] bg-06 bg-opacity-80 shadow-[30px_30px_4px_0px_rgba(0,0,0,0.15)] min-h-[65vh] max-h-[76vh] overflow-y-hidden overflow-x-hidden rounded-[40px] px-16 py-8 justify-center justify-items-center">
      <div className="w-full text-center flex">
        <div className="capitalButton absolute left-0 w-2/12 ">
          <button
            onClick={() => navigate(`/capital-sources?project=${searchParams.get('project')}`)}
          >
            <IoChevronBackCircleOutline size={40} color="rgba(193,155,22,1)" />
          </button>
        </div>
        <div className="capitalHeading w-full">
          <span className="bg-gradient-to-b font-libre from-[#9f9c5d] to-[rgba(193,155,22,1)] text-3xl text-transparent bg-clip-text">
            {projectName} Capital Sources
          </span>
        </div>
        <div className="capitalButton absolute right-0 w-2/12 ">
          <button onClick={addRow}>
            <IoAddCircleOutline size={40} color="rgba(193,155,22,1)" />
          </button>
        </div>
      </div>

      <div className="mt-8">
        <Formik initialValues={formDefaultValues} onSubmit={handleSubmit}>
          {({ handleSubmit, isSubmitting, touched, errors }) => (
            <section>
              <div className="min-h-[48vh] max-h-[48vh] pt-4 overflow-y-auto mt-5">
                <form onSubmit={handleSubmit}>
                  <table className="w-full xl:w-[90%] mx-auto">
                    <thead className="font-libre font-semibold text-02">
                      <tr className="graph-title">
                        <th className="px-6 py-3 font-semibold tracking-wider text-02">Source</th>
                        <th className="px-6 py-3 font-semibold tracking-wider text-02">Amount</th>
                        <th className="px-6 py-3 font-semibold tracking-wider text-02">
                          First Draw Date
                        </th>
                        <th className="px-6 py-3 font-semibold tracking-wider text-02">
                          Last Draw Date
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <Select
                              className="w-[10rem] xl:w-[10rem] text-center 2xl:w-[12rem] px-2 xl:px-3"
                              value={row.capital_source}
                              onChange={(e) =>
                                handleRowChange(index, 'capital_source', e.target.value)
                              }
                              required
                            >
                              <option value="" selected disabled>
                                Select
                              </option>
                              <option value="Cash Equity">Cash Equity</option>
                              <option value="Construction Term Loan">Construction Term Loan</option>
                              <option value="Shareholder Loan">Shareholder Loan</option>
                              <option value="VTB Mortgage">VTB Mortgage</option>
                              <option value="Construction Revolver">Construction Revolver</option>
                              <option value="Land Loan">Land Loan</option>
                              <option value="Pre-Construction Loan">Pre-Construction Loan</option>
                              <option value="Bridge Loan">Bridge Loan</option>
                              <option value="Long Term Debt">Long Term Debt</option>
                              <option value="Purchasers Deposits">Purchasers Deposits</option>
                              <option value="Deferred Costs">Deferred Costs</option>
                              <option value="Subordinate Loan">Subordinate Loan</option>
                              <option value="Land Equity">Land Equity</option>
                            </Select>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <TextField
                              className="w-[10rem] xl:w-[10rem] 2xl:w-[12rem] px-0"
                              type="text"
                              required
                              value={row.amount}
                              onChange={(event) => {
                                const value = event.target.value
                                handleRowChange(index, 'amount', value)
                              }}
                            />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <TextField
                              className="w-[10rem] xl:w-[10rem] 2xl:w-[12rem]"
                              type="date"
                              required
                              value={row.start_date}
                              onChange={(e) => handleRowChange(index, 'start_date', e.target.value)}
                            />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <TextField
                              className="w-[10rem] xl:w-[10rem] 2xl:w-[12rem]"
                              type="date"
                              required
                              value={row.end_date}
                              onChange={(e) => handleRowChange(index, 'end_date', e.target.value)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Button
                    title="Save"
                    className="h-16 mt-10 w-[120px] mx-auto"
                    isSubmitting={isSubmitting}
                    onClick={handleSubmit}
                  />
                </form>
              </div>
            </section>
          )}
        </Formik>
      </div>
    </div>
  )
}
