import { IoChevronBackCircleOutline, IoAddCircleOutline } from 'react-icons/io5'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDebtTransactions } from 'data/debt'
import { useAppContext } from 'context'
import { toast } from 'sonner'
import { formatter } from 'lib/utils'
import CircularProgress from '@mui/material/CircularProgress'

export default function DebtTransactionHistory() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { developer } = useAppContext()

  const id = searchParams.get('cl-data-id')

  const {
    data: debtTransactions,
    isLoading: isLoadingTransactions,
    isError: isErrorFetchingTransactions,
  } = useDebtTransactions({ developer: developer.split(' ')[0] })

  if (isErrorFetchingTransactions) {
    toast.error('Error fetching debt transactions')
  }

  return (
    <div className=" relative w-[90%] bg-06 bg-opacity-80 shadow-[30px_30px_4px_0px_rgba(0,0,0,0.15)]  h-[75vh]  overflow-y-hidden overflow-x-hidden rounded-[40px] px-16 py-8 justify-center justify-items-center">
      <div className="w-full text-center">
        <span className="bg-gradient-to-b font-libre from-[#9f9c5d] to-[rgba(193,155,22,1)] text-4xl text-transparent bg-clip-text">
          Debt Transaction
        </span>
        <span className="text-09 text-opacity-80 font-libre  text-4xl"> History</span>
      </div>
      <button onClick={() => navigate('/app-data/debt')} className="top-8 absolute">
        <IoChevronBackCircleOutline size={40} color="rgba(193,155,22,1)" />
      </button>
      <button
        className="top-8 absolute right-20"
        onClick={() => navigate(`/app-data/debt/transaction/new?cl-data-id=${id}`)}
      >
        <IoAddCircleOutline size={40} color="rgba(193,155,22,1)" />
      </button>
      {isLoadingTransactions ? (
        <div className="grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-02" size={30} />
        </div>
      ) : debtTransactions ? (
        debtTransactions.length ? (
          <div className="  max-h-[58vh]  pt-8 overflow-y-auto flex justify-center  ">
            <table className=" w-[90%] rounded-md ">
              <thead className="font-libre font-semibold text-02  ">
                <tr className=" graph-title  text-left h-[7vh] ">
                  <th className=" graph-title font-semibold font-libre   tracking-wider text-02">
                    Loan Name
                  </th>
                  <th className="font-semibold  font-libre   tracking-wider text-02">
                    Transaction Type
                  </th>
                  <th className="  font-semibold  font-libre   tracking-wider text-02">Amount</th>
                  <th className=" font-semibold font-libre   tracking-wider text-02">
                    Transaction Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {debtTransactions
                  .filter((item) => item.debt_commitment_letter === Number(id))
                  .map((debtTransaction) => (
                    <tr
                      className="hover:bg-[#c9cd77] cursor-pointer"
                      onClick={() => navigate(`/app-data/debt/transaction/${debtTransaction.id}`)}
                    >
                      <td className="py-3 text-sm font-libre tracking-wider text-02">
                        {debtTransaction.loan_name.replace(/_/g, ' ')}
                      </td>
                      <td className="py-3 text-sm font-libre tracking-wider text-02">
                        {debtTransaction.transaction_type}
                      </td>
                      <td className="py-3 text-sm font-libre tracking-wider text-02">
                        ${formatter.format(Number(debtTransaction.amount))}
                      </td>
                      <td className="py-3 text-sm font-libre tracking-wider text-02">
                        {debtTransaction.transaction_date}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className=" mt-40 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
            You haven't added a debt transaction
          </p>
        )
      ) : null}
    </div>
  )
}
