import React from 'react'
import { twMerge } from 'tailwind-merge'

export const DataEntryButton = ({ title, icon }) => {
  return (
    <div className="buttonImage container-shadow w-300px bg-[#82b74b] rounded-full text-white font-regular text-xs text-center font-libre pt-1 pl-2 pr-2">
      <button className="flex py-1">
        <span className="pr-2">{title}</span>
        <span className="pl-3">{icon}</span>
      </button>
    </div>
  )
}

export const UploadFileButton = ({ disabled, title, icon, className, onClick }) => {
  return (
    <button
      className={twMerge(
        'container-shadow relative h-[35px] grid grid-cols-[auto_auto] px-3 gap-x-3 justify-center items-center bg-gradient-to-r from-[rgba(238,136,99,1)] to-[rgba(173,43,15,0.76)] rounded-full ',
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      <span className="line-clamp-1 text-white text-xs  font-medium font-libre ">{title}</span>
      {icon}
    </button>
  )
}

export const AddedFileButton = ({ title, icon, className }) => {
  return (
    <button
      className={twMerge(
        'container-shadow h-[35px] cursor-default flex gap-x-3 justify-center items-center bg-gradient-to-r from-[rgba(141,190,144,1)] to-[rgba(9,145,18,0.775)] rounded-full ',
        className
      )}
    >
      <span className=" text-white text-sm font-medium font-libre ">{title}</span>
      {icon}
    </button>
  )
}

export const EditFileButton = ({ disabled, title, icon, className, onClick }) => {
  return (
    <button
      className={twMerge(
        'container-shadow h-[35px] px-3 grid grid-cols-[auto_auto] gap-x-3 justify-center items-center bg-gradient-to-r from-[rgba(141,190,144,1)] to-[rgba(9,145,18,0.775)] rounded-full ',
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      <span className=" text-white  line-clamp-1  text-xs font-medium font-libre ">{title}</span>
      {icon}
    </button>
  )
}
