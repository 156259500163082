import React from 'react'
import StackedAreaChart from './stacked-area-chart'
import FinancialRatios from './financial-ratios'
import { useCashflowForecast, useFinancialRatios, useVarianceAnalysis } from 'data/solvency'
import { useAppContext } from 'context'
import { CircularProgress } from '@mui/material'
import { toast } from 'sonner'
import { groupBy } from 'lib/utils'
import { useDeveloperProjects } from 'data/project'
import StackedBarChart from './stacked-bar-chart'

export default function Solvency() {
  const { developer } = useAppContext()
  const cashflowForecast = useCashflowForecast({ developer: developer.split(' ')?.[0] })
  const varianceAnalysis = useVarianceAnalysis({ developer: developer.split(' ')?.[0] })
  const financialRatios = useFinancialRatios({ developer: developer.split(' ')?.[0] })

  if (cashflowForecast.isError || varianceAnalysis.isError || financialRatios.isError) {
    toast.error('An error occurred while fetching graph information')
  }

  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorFetchingProjects,
  } = useDeveloperProjects({ developer: developer.split(' ')?.[0] })

  if (isErrorFetchingProjects) {
    toast.error('Error fetching developer projects')
  }

  const groupedData = groupBy(varianceAnalysis?.data ?? [], 'period')

  return (
    <div className="w-[95%] bg-06 bg-opacity-80 overflow-y-auto overflow-x-auto 2xl:overflow-x-hidden shadow-[30px_30px_4px_0px_rgba(0,0,0,0.15)]  h-[80vh]  rounded-[40px] p-4 2xl:py-8 2xl:px-16">
      <div className="w-full text-center font-libre text-2xl 2xl:text-4xl">
        <span className="bg-gradient-to-b  from-[#908d54] to-[rgba(193,155,22,1)]  text-transparent bg-clip-text  ">
          Solvency
        </span>
        <span className="text-09 text-opacity-80 "> Outlook</span>
      </div>
      {isLoadingProjects ? (
        <div className=" grid place-items-center mt-52">
          <CircularProgress color="inherit" className="text-02" size={30} />
        </div>
      ) : (
        projects &&
        (projects.length ? (
          <>
            <p className="graph-title text-02 font-semibold text-base 2xl:text-lg font-libre">
              3-Year Cashflow Forecast
            </p>
            <div className="grid grid-rows-[auto_auto_auto_auto]">
              {cashflowForecast.isLoading ? (
                <div className=" mt-36 mb-20  w-full grid place-items-center">
                  <CircularProgress color="inherit" className="text-02" size={30} />
                </div>
              ) : cashflowForecast.data?.length ? (
                <div className="-mt-11 2xl:-mt-3 -mx-32">
                  <StackedAreaChart data={cashflowForecast.data} />
                </div>
              ) : (
                <p className=" my-32 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
                  No cashflow forecast
                </p>
              )}

              <div className="h-[5px] -mt-6 bg-gradient-to-r from-02 via-[#DAA627] to-[#E78833]"></div>
              <div className="grid grid-cols-[auto_auto_auto] -mt-1 2xl:pt-5">
                {varianceAnalysis.isLoading ? (
                  <div className=" mt-36 mb-24 ">
                    <CircularProgress color="inherit" className="text-02" size={30} />
                  </div>
                ) : varianceAnalysis.data.length ? (
                  <StackedBarChart data={groupedData} />
                ) : (
                  <p className=" my-20 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
                    No variance analysis
                  </p>
                )}
                <div className=" w-1 h-48 mt-10 mr-6 bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
                {financialRatios.isLoading ? (
                  <div className=" mt-36 mb-24 ">
                    <CircularProgress color="inherit" className="text-02" size={30} />
                  </div>
                ) : financialRatios.data.length &&
                  financialRatios.data?.[0]?.current_profit_margin ? (
                  <FinancialRatios data={financialRatios.data} />
                ) : (
                  <p className=" my-20 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
                    No financial ratios
                  </p>
                )}
              </div>
              <div className="flex justify-between mt-5 text-02  font-semibold text-base 2xl:text-lg font-libre">
                <p className=" graph-title ">Variance Analysis</p>
                <p className="graph-title ">Financial Ratios</p>
              </div>
            </div>
          </>
        ) : (
          <p className=" mt-52 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
            No active project
          </p>
        ))
      )}
    </div>
  )
}
