import React from 'react'
import GaugeComponent from 'react-gauge-component'
import Tooltip from '@mui/material/Tooltip'

export default function AnnualizedRoi({ data }) {
  const items = data?.map((item) => ({
    project: item.project,
    currentValue: `${(Number(item.current_aroi) * 100).toFixed(2)}`,
    targetValue: (Number(item.initial_aroi) * 100).toFixed(2),
  }))

  return (
    <div className="grid grid-cols-[130px_130px] 2xl:grid-cols-[auto_auto] w-fit items-center justify-center  h-52 -gap-x-10 -mt-5 xl:-ml-16 2xl:-ml-10 2xl:-mt-0">
      {items?.map((item, index) => (
        <div key={item.currentValue} className="relative text-black text-2xl">
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
            title={
              <div className="flex flex-col gap-y-2 text-sm">
                <span>{item.project} </span>
                <span>Initial ROI: {item.targetValue}% </span>
                <span>Current ROI: {item.currentValue}% </span>
              </div>
            }
          >
            <div key={item.value} className="relative">
              <GaugeComponent
                className=""
                type="semicircle"
                minValue={-20}
                maxValue={40}
                style={{ width: 150 }}
                marginInPercent={{ top: 0.08, bottom: 0.0, left: 0.0, right: 0.0 }}
                id={index}
                nrOfLevels={2}
                cornerRadius={2}
                arc={{
                  cornerRadius: 2,
                  width: 0.3,
                  colorArray: ['#efa57f', '#9dc498'],
                  padding: 0.05,
                  subArcs: [
                    { limit: 10, color: '#efa57f' },
                    { limit: 40, color: '#9dc498' },
                  ],
                }}
                pointer={{
                  type: 'arrow',
                  color: `${Number(item.currentValue) <= 10 ? '#a43425' : '#42644c'}`,
                  baseColor: `${Number(item.currentValue) <= 10 ? '#a43425' : '#42644c'}`,
                  length: 1,
                  width: 15,
                  elastic: true,
                }}
                // arcWidth={0.}
                // arcsLength={[10, 40]}
                labels={{
                  valueLabel: {
                    formatTextValue: (value) => `${value}%`,
                    style: {
                      fontSize: '26px',
                      textShadow: 'none',
                      fill: `${Number(item.currentValue) <= 10 ? '#a43425' : '#42644c'}`,
                    },
                  },
                  tickLabels: {
                    hideMinMax: true,
                    type: 'outer',

                    ticks: [{ value: item.targetValue }],
                    defaultTickValueConfig: {
                      formatTextValue: (value) => `${value}%`,
                      style: {
                        fontSize: '0px',
                        opacity: 0,
                        textShadow: 'none',
                        fill: `${Number(item.targetValue) <= 10 ? '#a43425' : '#42644c'}`,
                      },
                    },
                    defaultTickLineConfig: {
                      width: 4,
                      length: 20,
                      distanceFromArc: -15,
                      color: `${Number(item.targetValue) <= 10 ? '#a43425' : '#42644c'}`,
                    },
                  },
                }}
                textComponentContainerClassName="text-xl"
                textColor={Number(item.currentValue) <= 10 ? '#a43425' : '#42644c'}
                value={Number(item.currentValue)}
              />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  )
}
