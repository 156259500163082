export const TREASURY_SUBMENU_OPTIONS = [
  { label: 'Dashboards', value: 'dashboard', url: '/dashboard/liquidity' },
  { label: 'Reports', value: 'report', url: '/report' },
  { label: 'App Data', value: 'app-data', url: '/app-data/liquidity' },
  { label: 'Client Data', value: 'client-data', url: '/client-data-form' },
  { label: 'Checklist', value: 'checklist', url: '/checklist' },
  { label: 'Admin', value: 'admin', url: '/admin' },
]

export const REPORTS_LIST = [
  { label: 'Liquidity Report', value: 'liquidity', url: 'a-liquidity/liquidity-reports/' },
  { label: 'Debt Report', value: 'debt', url: 'b-debts/debts-reports/' },
  { label: 'Solvency Report', value: 'solvency', url: 'c-solvency/solvency-reports/' },
  { label: 'Capital Report', value: 'capital', url: 'd-capital/capital-reports/' },
  { label: 'LTP.csv', value: 'ltp', url: 'a-liquidity/generate-last-transaction-prediction/' },
]

export const DEVELOPER_SUBMENU_OPTIONS = [
  { label: 'Dashboards', value: 'dashboard', url: '/dashboard/liquidity' },
  { label: 'Reports', value: 'report', url: '/report' },
]

export const PROJECT_DATA_MENU = [
  { label: 'Add Project Data', url: '/project-data-form' },
  { label: 'Add Capital Sources', value: 'app-data', url: '/capital-sources-form' },
]

export const DEVELOPERS = ['Glenn Mews', 'Trojan Meadows', 'Kimberly Heights', 'Nuel Haven']

export const CREDIT_TERM_STAGES = [
  {
    name: 'Approval-OPA',
    code: 'OP',
    color: '#8fbd90',
    opacity: '30%',
    width: 32,
  },
  {
    name: 'Approval-ZBA',
    code: 'ZB',
    color: '#eb8761',
    opacity: '30%',
    width: 32 * 2,
  },
  {
    name: 'Approval-SPA-DPA',
    code: 'SP',
    color: '#819175',
    opacity: '60%',
    width: 32 * 3,
  },
  {
    name: 'Servicing',
    code: 'S',
    color: '#be9b17',
    opacity: '30%',
    width: 32 * 4,
  },

  {
    name: 'Development Permit',
    code: 'DP',
    color: '#eb8761',
    opacity: '30%',
    width: 32 * 5,
  },
  {
    name: 'Presales',
    code: 'PS',
    color: '#607003',
    opacity: '45%',
    width: 32 * 6,
  },
  {
    name: 'Building Permit',
    code: 'BP',
    color: '#819175',
    opacity: '60%',
    width: 32 * 7,
  },

  {
    name: 'Construction',
    code: 'C',
    color: '#847004',
    opacity: '50%',
    width: 32 * 8,
  },
  {
    name: 'Occupancy',
    code: 'OC',
    color: '#b72f11',
    opacity: '45%',
    width: 32 * 9,
  },
]

export const MILESTONE_TRACKER = [
  {
    name: 'First Contact',
    code: 'FC',
    color: '#8fbd90',
    opacity: '30%',
    width: 50,
  },
  {
    name: 'Discussion Paper',
    code: 'DP',
    color: '#eb8761',
    opacity: '40%',
    width: 50 * 2,
  },
  {
    name: 'Letter of Interest',
    code: 'LOI',
    color: '#819175',
    opacity: '60%',
    width: 50 * 3,
  },

  {
    name: 'Commitment Letter',
    code: 'CL',
    color: '#f3da76',
    opacity: '40%',
    width: 50 * 4,
  },
  {
    name: 'Closing Date',
    code: 'CD',
    color: '#eba769',
    opacity: '80%',
    width: 50 * 5,
  },
]

export const CURRENT_DEVELOPER_ID_KEY = 'CURRENT_DEVELOPER_ID'
export const CURRENT_DEVELOPER_ORG_NAME = 'CURRENT_DEVELOPER_ORG_NAME'
export const LOGOUT_TIMEOUT = 15 * 60 * 1000 // 15 minutes
