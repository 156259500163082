import React from 'react'
import { twMerge } from 'tailwind-merge'
import { CircularProgress } from '@mui/material'

export const Button = ({ title, className, isSubmitting, onClick, ...rest }) => {
  return (
    <button
      onClick={onClick}
      disabled={isSubmitting}
      className={twMerge(
        'grid place-items-center  bg-gradient-base-bg bg-cover bg-opacity-80 shadow-[6px_6px_4px_0px_rgba(0,0,0,0.15)] font-libre  text-white rounded-full ',
        className
      )}
      {...rest}
    >
      {isSubmitting ? (
        <>
          <CircularProgress color="inherit" className="text-white" size={22} />
          <span className="sr-only">Submitting</span>
        </>
      ) : (
        <span>{title}</span>
      )}
    </button>
  )
}
export default Button
