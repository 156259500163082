import ReactEcharts from 'echarts-for-react'
import { formatter } from 'lib/utils'

const colorPalette = ['#8fbd90', '#eb8761', '#f4d151', '#819175', '#d8e5bd']

export default function StackedBarChart({ data, name }) {
  const items = Object.keys(data).map((item) => item)

  const a1 =
    data[items[0]]?.map((item) => ({
      amount: item.amount,
      loan: item.loan_name,
      purpose: item.purpose,
      closing_date: item.required_closing_date,
      project: item.project,
    })) ?? []
  const a2 =
    data[items[1]]?.map((item) => ({
      amount: item.amount,
      loan: item.loan_name,
      purpose: item.purpose,
      closing_date: item.required_closing_date,
      project: item.project,
    })) ?? []
  const a3 =
    data[items[2]]?.map((item) => ({
      amount: item.amount,
      loan: item.loan_name,
      purpose: item.purpose,
      closing_date: item.required_closing_date,
      project: item.project,
    })) ?? []
  const a4 =
    data[items[3]]?.map((item) => ({
      amount: item.amount,
      loan: item.loan_name,
      purpose: item.purpose,
      closing_date: item.required_closing_date,
      project: item.project,
    })) ?? []
  const a5 =
    data[items[4]]?.map((item) => ({
      amount: item.amount,
      loan: item.loan_name,
      purpose: item.purpose,
      closing_date: item.required_closing_date,
      project: item.project,
    })) ?? []
  const a6 =
    data[items[5]]?.map((item) => ({
      amount: item.amount,
      loan: item.loan_name,
      purpose: item.purpose,
      closing_date: item.required_closing_date,
      project: item.project,
    })) ?? []
  const a7 =
    data[items[6]]?.map((item) => ({
      amount: item.amount,
      loan: item.loan_name,
      purpose: item.purpose,
      closing_date: item.required_closing_date,
      project: item.project,
    })) ?? []
  const a8 =
    data[items[7]]?.map((item) => ({
      amount: item.amount,
      loan: item.loan_name,
      purpose: item.purpose,
      closing_date: item.required_closing_date,
      project: item.project,
    })) ?? []
  const a9 =
    data[items[8]]?.map((item) => ({
      amount: item.amount,
      loan: item.loan_name,
      purpose: item.purpose,
      closing_date: item.required_closing_date,
      project: item.project,
    })) ?? []

  const option = {
    tooltip: {
      trigger: 'item',
      borderColor: '#f9eeca',
      backgroundColor: '#f9eeca',
      textStyle: {
        color: '#42644c ',
        fontFamily: 'libre',
      },
      formatter: (params) => {
        return `Project: ${params?.data?.project} <br /> ${name === 'maturity' ? `Loan: ${params?.data?.loan?.replace(/_/g, ' ')} <br /> ` : ''}  Purpose: ${params?.data?.purpose}  <br /> Amount: ${params?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.value))}  ${name === 'financing' ? `<br /> Required closing date: ${params?.data?.closing_date}` : ''} `
      },
      axisPointer: {
        type: 'shadow',
      },
      position: 'top',
    },

    yAxis: [
      {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    xAxis: [
      {
        type: 'category',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#42644cb3',
            width: 3,
            cap: 'butt',
          },
        },
        axisLabel: {
          show: true,
          color: '#42644c',
          fontSize: 12,
          fontFamily: 'libre',
        },
        data: Object.keys(data),

        axisTick: { show: false },
      },
    ],
    series: [
      {
        stack: 'one',
        data: [
          {
            value: a1[0]?.amount ?? 0,
            project: a1[0]?.project,
            loan: a1[0]?.loan,
            purpose: a1[0]?.purpose,
            closing_date: a1[0]?.closing_date,
          },
          {
            value: a2[0]?.amount ?? 0,
            project: a2[0]?.project,
            loan: a2[0]?.loan,
            purpose: a2[0]?.purpose,
            closing_date: a2[0]?.closing_date,
          },
          {
            value: a3[0]?.amount ?? 0,
            project: a3[0]?.project,
            loan: a3[0]?.loan,
            purpose: a3[0]?.purpose,
            closing_date: a3[0]?.closing_date,
          },
          {
            value: a4[0]?.amount ?? 0,
            project: a4[0]?.project,
            loan: a4[0]?.loan,
            purpose: a4[0]?.purpose,
            closing_date: a4[0]?.closing_date,
          },
          {
            value: a5[0]?.amount ?? 0,
            project: a5[0]?.project,
            loan: a5[0]?.loan,
            purpose: a5[0]?.purpose,
            closing_date: a5[0]?.closing_date,
          },
          {
            value: a6[0]?.amount ?? 0,
            project: a6[0]?.project,
            loan: a6[0]?.loan,
            purpose: a6[0]?.purpose,
            closing_date: a6[0]?.closing_date,
          },
          {
            value: a7[0]?.amount ?? 0,
            project: a7[0]?.project,
            loan: a7[0]?.loan,
            purpose: a7[0]?.purpose,
            closing_date: a7[0]?.closing_date,
          },
          {
            value: a8[0]?.amount ?? 0,
            project: a8[0]?.project,
            loan: a8[0]?.loan,
            purpose: a8[0]?.purpose,
            closing_date: a8[0]?.closing_date,
          },
          {
            value: a9[0]?.amount ?? 0,
            project: a9[0]?.project,
            loan: a9[0]?.loan,
            purpose: a9[0]?.purpose,
            closing_date: a9[0]?.closing_date,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[0],
        },
        label: {
          show: false,
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: a1[1]?.amount ?? 0,
            project: a1[1]?.project,
            loan: a1[1]?.loan,
            purpose: a1[1]?.purpose,
            closing_date: a1[1]?.closing_date,
          },
          {
            value: a2[1]?.amount ?? 0,
            project: a2[1]?.project,
            loan: a2[1]?.loan,
            purpose: a2[1]?.purpose,
            closing_date: a2[1]?.closing_date,
          },
          {
            value: a3[1]?.amount ?? 0,
            project: a3[1]?.project,
            loan: a3[1]?.loan,
            purpose: a3[1]?.purpose,
            closing_date: a3[1]?.closing_date,
          },
          {
            value: a4[1]?.amount ?? 0,
            project: a4[1]?.project,
            loan: a4[1]?.loan,
            purpose: a4[1]?.purpose,
            closing_date: a4[1]?.closing_date,
          },
          {
            value: a5[1]?.amount ?? 0,
            project: a5[1]?.project,
            loan: a5[1]?.loan,
            purpose: a5[1]?.purpose,
            closing_date: a5[1]?.closing_date,
          },
          {
            value: a6[1]?.amount ?? 0,
            project: a6[1]?.project,
            loan: a6[1]?.loan,
            purpose: a6[1]?.purpose,
            closing_date: a6[1]?.closing_date,
          },
          {
            value: a7[1]?.amount ?? 0,
            project: a7[1]?.project,
            loan: a7[1]?.loan,
            purpose: a7[1]?.purpose,
            closing_date: a7[1]?.closing_date,
          },
          {
            value: a8[1]?.amount ?? 0,
            project: a8[1]?.project,
            loan: a8[1]?.loan,
            purpose: a8[1]?.purpose,
            closing_date: a8[1]?.closing_date,
          },
          {
            value: a9[1]?.amount ?? 0,
            project: a9[1]?.project,
            loan: a9[1]?.loan,
            purpose: a9[1]?.purpose,
            closing_date: a9[1]?.closing_date,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[1],
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: a1[2]?.amount ?? 0,
            project: a1[2]?.project,
            loan: a1[2]?.loan,
            purpose: a1[2]?.purpose,
            closing_date: a1[2]?.closing_date,
          },
          {
            value: a2[2]?.amount ?? 0,
            project: a2[2]?.project,
            loan: a2[2]?.loan,
            purpose: a2[2]?.purpose,
            closing_date: a2[2]?.closing_date,
          },
          {
            value: a3[2]?.amount ?? 0,
            project: a3[2]?.project,
            loan: a3[2]?.loan,
            purpose: a3[2]?.purpose,
            closing_date: a3[2]?.closing_date,
          },
          {
            value: a4[2]?.amount ?? 0,
            project: a4[2]?.project,
            loan: a4[2]?.loan,
            purpose: a4[2]?.purpose,
            closing_date: a4[2]?.closing_date,
          },
          {
            value: a5[2]?.amount ?? 0,
            project: a5[2]?.project,
            loan: a5[2]?.loan,
            purpose: a5[2]?.purpose,
            closing_date: a5[2]?.closing_date,
          },
          {
            value: a6[2]?.amount ?? 0,
            project: a6[2]?.project,
            loan: a6[2]?.loan,
            purpose: a6[2]?.purpose,
            closing_date: a6[2]?.closing_date,
          },
          {
            value: a7[2]?.amount ?? 0,
            project: a7[2]?.project,
            loan: a7[2]?.loan,
            purpose: a7[2]?.purpose,
            closing_date: a7[2]?.closing_date,
          },
          {
            value: a8[2]?.amount ?? 0,
            project: a8[2]?.project,
            loan: a8[2]?.loan,
            purpose: a8[2]?.purpose,
            closing_date: a8[2]?.closing_date,
          },
          {
            value: a9[2]?.amount ?? 0,
            project: a9[2]?.project,
            loan: a9[2]?.loan,
            purpose: a9[2]?.purpose,
            closing_date: a9[2]?.closing_date,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[2],
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: a1[3]?.amount ?? 0,
            project: a1[3]?.project,
            loan: a1[3]?.loan,
            purpose: a1[3]?.purpose,
            closing_date: a1[3]?.closing_date,
          },
          {
            value: a2[3]?.amount ?? 0,
            project: a2[3]?.project,
            loan: a2[3]?.loan,
            purpose: a2[3]?.purpose,
            closing_date: a2[3]?.closing_date,
          },
          {
            value: a3[3]?.amount ?? 0,
            project: a3[3]?.project,
            loan: a3[3]?.loan,
            purpose: a3[3]?.purpose,
            closing_date: a3[3]?.closing_date,
          },
          {
            value: a4[3]?.amount ?? 0,
            project: a4[3]?.project,
            loan: a4[3]?.loan,
            purpose: a4[3]?.purpose,
            closing_date: a4[3]?.closing_date,
          },
          {
            value: a5[3]?.amount ?? 0,
            project: a5[3]?.project,
            loan: a5[3]?.loan,
            purpose: a5[3]?.purpose,
            closing_date: a5[3]?.closing_date,
          },
          {
            value: a6[3]?.amount ?? 0,
            project: a6[3]?.project,
            loan: a6[3]?.loan,
            purpose: a6[3]?.purpose,
            closing_date: a6[3]?.closing_date,
          },
          {
            value: a7[3]?.amount ?? 0,
            project: a7[3]?.project,
            loan: a7[3]?.loan,
            purpose: a7[3]?.purpose,
            closing_date: a7[3]?.closing_date,
          },
          {
            value: a8[3]?.amount ?? 0,
            project: a8[3]?.project,
            loan: a8[3]?.loan,
            purpose: a8[3]?.purpose,
            closing_date: a8[4]?.closing_date,
          },
          {
            value: a9[3]?.amount ?? 0,
            project: a9[3]?.project,
            loan: a9[3]?.loan,
            purpose: a9[3]?.purpose,
            closing_date: a9[3]?.closing_date,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[3],
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: a1[4]?.amount ?? 0,
            project: a1[4]?.project,
            loan: a1[4]?.loan,
            purpose: a1[4]?.purpose,
            closing_date: a1[4]?.closing_date,
          },
          {
            value: a2[4]?.amount ?? 0,
            project: a2[4]?.project,
            loan: a2[4]?.loan,
            purpose: a2[4]?.purpose,
            closing_date: a2[4]?.closing_date,
          },
          {
            value: a3[4]?.amount ?? 0,
            project: a3[4]?.project,
            loan: a3[4]?.loan,
            purpose: a3[4]?.purpose,
            closing_date: a3[4]?.closing_date,
          },
          {
            value: a4[4]?.amount ?? 0,
            project: a4[4]?.project,
            loan: a4[4]?.loan,
            purpose: a4[4]?.purpose,
            closing_date: a4[4]?.closing_date,
          },
          {
            value: a5[4]?.amount ?? 0,
            project: a5[4]?.project,
            loan: a5[4]?.loan,
            purpose: a5[4]?.purpose,
            closing_date: a5[4]?.closing_date,
          },
          {
            value: a6[4]?.amount ?? 0,
            project: a6[4]?.project,
            loan: a6[4]?.loan,
            purpose: a6[4]?.purpose,
            closing_date: a6[4]?.closing_date,
          },
          {
            value: a7[4]?.amount ?? 0,
            project: a7[4]?.project,
            loan: a7[4]?.loan,
            purpose: a7[4]?.purpose,
            closing_date: a7[4]?.closing_date,
          },
          {
            value: a8[4]?.amount ?? 0,
            project: a8[4]?.project,
            loan: a8[4]?.loan,
            purpose: a8[4]?.purpose,
            closing_date: a8[4]?.closing_date,
          },
          {
            value: a9[4]?.amount ?? 0,
            project: a9[4]?.project,
            loan: a9[4]?.loan,
            purpose: a9[4]?.purpose,
            closing_date: a9[4]?.closing_date,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[4],
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: a1[5]?.amount ?? 0,
            project: a1[5]?.project,
            loan: a1[5]?.loan,
            purpose: a1[5]?.purpose,
            closing_date: a7[4]?.closing_date,
          },
          {
            value: a2[5]?.amount ?? 0,
            project: a2[5]?.project,
            loan: a2[5]?.loan,
            purpose: a2[5]?.purpose,
            closing_date: a7[4]?.closing_date,
          },
          {
            value: a3[5]?.amount ?? 0,
            project: a3[5]?.project,
            loan: a3[5]?.loan,
            purpose: a3[5]?.purpose,
            closing_date: a7[4]?.closing_date,
          },
          {
            value: a4[5]?.amount ?? 0,
            project: a4[5]?.project,
            loan: a4[5]?.loan,
            purpose: a4[5]?.purpose,
            closing_date: a7[4]?.closing_date,
          },
          {
            value: a5[5]?.amount ?? 0,
            project: a5[5]?.project,
            loan: a5[5]?.loan,
            purpose: a5[5]?.purpose,
            closing_date: a7[4]?.closing_date,
          },
          {
            value: a6[5]?.amount ?? 0,
            project: a6[5]?.project,
            loan: a6[5]?.loan,
            purpose: a6[5]?.purpose,
          },
          {
            value: a7[5]?.amount ?? 0,
            project: a7[5]?.project,
            loan: a7[5]?.loan,
            purpose: a7[5]?.purpose,
          },
          {
            value: a8[5]?.amount ?? 0,
            project: a8[5]?.project,
            loan: a8[5]?.loan,
            purpose: a8[5]?.purpose,
          },
          {
            value: a9[5]?.amount ?? 0,
            project: a9[5]?.project,
            loan: a9[5]?.loan,
            purpose: a9[5]?.purpose,
          },
        ],
        type: 'bar',
      },
      {
        stack: 'one',
        data: [
          {
            value: a1[6]?.amount ?? 0,
            project: a1[6]?.project,
            loan: a1[6]?.loan,
            purpose: a1[6]?.purpose,
            closing_date: a1[6]?.closing_date,
          },
          {
            value: a2[6]?.amount ?? 0,
            project: a2[6]?.project,
            loan: a2[6]?.loan,
            purpose: a2[6]?.purpose,
            closing_date: a2[6]?.closing_date,
          },
          {
            value: a3[6]?.amount ?? 0,
            project: a3[6]?.project,
            loan: a3[6]?.loan,
            purpose: a3[6]?.purpose,
            closing_date: a3[6]?.closing_date,
          },
          {
            value: a4[6]?.amount ?? 0,
            project: a4[6]?.project,
            loan: a4[6]?.loan,
            purpose: a4[6]?.purpose,
            closing_date: a4[6]?.closing_date,
          },
          {
            value: a5[6]?.amount ?? 0,
            project: a5[6]?.project,
            loan: a5[6]?.loan,
            purpose: a5[6]?.purpose,
            closing_date: a5[6]?.closing_date,
          },
          {
            value: a6[6]?.amount ?? 0,
            project: a6[6]?.project,
            loan: a6[6]?.loan,
            purpose: a6[6]?.purpose,
            closing_date: a6[6]?.closing_date,
          },
          {
            value: a7[6]?.amount ?? 0,
            project: a7[6]?.project,
            loan: a7[6]?.loan,
            purpose: a7[6]?.purpose,
            closing_date: a7[6]?.closing_date,
          },
          {
            value: a8[6]?.amount ?? 0,
            project: a8[6]?.project,
            loan: a8[6]?.loan,
            purpose: a8[6]?.purpose,
            closing_date: a8[6]?.closing_date,
          },
          {
            value: a9[6]?.amount ?? 0,
            project: a9[6]?.project,
            loan: a9[6]?.loan,
            purpose: a9[6]?.purpose,
            closing_date: a9[6]?.closing_date,
          },
        ],
        type: 'bar',
      },
    ],
  }
  return <ReactEcharts option={option} style={{ height: '300px', width: '280px' }} />
}
