import { IoIosArrowDown } from 'react-icons/io'
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { twMerge } from 'tailwind-merge'
import { useEffect } from 'react'
import { useAuthActions } from 'context'
import { CURRENT_DEVELOPER_ID_KEY, CURRENT_DEVELOPER_ORG_NAME } from 'lib/constants'

export const DeveloperDropdown = ({ options }) => {
  const { updateDeveloper } = useAuthActions()
  const currentDeveloper = options?.find(
    (item) => item.value === sessionStorage.getItem(CURRENT_DEVELOPER_ORG_NAME)
  )?.label
  const [developer, setDeveloper] = useState(currentDeveloper ?? options?.[0]?.label)

  useEffect(() => {
    sessionStorage.setItem(
      CURRENT_DEVELOPER_ID_KEY,
      options?.find((item) => item.label === developer).id
    )
    sessionStorage.setItem(
      CURRENT_DEVELOPER_ORG_NAME,
      options?.find((item) => item.label === developer).value
    )
    updateDeveloper({ developer })
    // eslint-disable-next-line
  }, [developer])

  return (
    <>
      {options?.length ? (
        <Dropdown.Root>
          <Dropdown.Trigger className="py-2.5 focus:outline-none w-full z-[120]">
            <div
              className="grid justify-between px-4 2xl:px-8 items-center grid-cols-[auto_auto] w-full h-12 2xl:h-14  font-libre rounded-full"
              style={{
                backgroundImage: `url(${'/assets/images/button-gradient-bg.png'})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <span className="text-sm 2xl:text-base text-06 ">{developer}</span>
              <div className="w-full">
                <IoIosArrowDown color="#f7fff0" size={20} />
              </div>
            </div>
          </Dropdown.Trigger>
          <Dropdown.Portal>
            <Dropdown.Content
              className={twMerge(
                'z-[100] data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
                'min-w-[10rem] 2xl:min-w-[18rem] rounded-xl border border-bca-grey-2 bg-12 px-5 py-4 shadow-[0px_7px_7px_-2px_#10182808]',
                ' text-02 font-libre will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade'
              )}
              align="end"
            >
              <Dropdown.Group className=" divide-y-[2px] divide-02">
                {options.map(({ label, value }) => (
                  <Dropdown.Item
                    className="flex gap-x-2 items-center cursor-pointer text-center px-2 2xl:px-4 py-3 font-libre text-sm outline-none"
                    key={value}
                    onClick={() => setDeveloper(label)}
                  >
                    <span className={developer === label ? 'visible' : 'invisible'}>
                      <IoIosArrowBack color="#42644c" size={25} />
                    </span>

                    <p>{label}</p>
                  </Dropdown.Item>
                ))}
              </Dropdown.Group>
            </Dropdown.Content>
          </Dropdown.Portal>
        </Dropdown.Root>
      ) : null}
    </>
  )
}
