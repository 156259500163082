import React from 'react'
import { twMerge } from 'tailwind-merge'

export const ProgressBar = ({ progressPercentage }) => {
  return (
    <div className=" relative h-3.5 w-full bg-[#e8d794] bg-cover flex">
      <div
        style={{ width: `${progressPercentage}%` }}
        className=" absolute top-0 bg-gradient-base-bg bg-cover h-3.5"
      ></div>
      <div className="absolute top-0 -left-1 flex items-center h-full">
        <div className="w-5 h-5 rounded-full  bg-gradient-to-r from-[#72ae84] via-[#DAA627] to-[#E78833]"></div>
      </div>
      <div className="absolute top-0 left-1/2 transform -translate-x-1/2 flex items-center h-full">
        <div className="w-5 h-5 rounded-full  bg-gradient-to-r from-[#72ae84] via-[#DAA627] to-[#E78833]"></div>
      </div>

      <div className="absolute top-0 -right-1 flex items-center h-full">
        <div
          className={twMerge(
            'w-5 h-5 rounded-full ',
            progressPercentage === 100
              ? 'bg-gradient-to-r from-[#72ae84] via-[#DAA627] to-[#E78833]'
              : 'bg-[#e8d794]'
          )}
        ></div>
      </div>
    </div>
  )
}
