import { Tooltip } from '@mui/material'
import React from 'react'

const getColorCode = ({ current, previous, comparingFactor }) => {
  const ratio = Number(current?.replace('%', '')) - Number(previous?.replace('%', ''))

  if (ratio <= -comparingFactor) {
    return '#e9a17d'
  } else if (ratio > -comparingFactor && ratio < comparingFactor) {
    return '#f3da76'
  } else {
    return '#8fbd90'
  }
}

export default function FinancialRatios({ data }) {
  const ratios = [
    {
      name: 'Profit Margin',
      svgName: 'profitMargin',
      previous: `${Number(data[0]?.previous_profit_margin) * 100}`,
      current: `${Number(data[0]?.current_profit_margin) * 100}`,
      comparingFactor: 2,
    },
    {
      name: 'Cash on Cash',
      svgName: 'cashOnCash',
      previous: data[0]?.previous_cash_on_cash_return,
      current: data[0]?.current_cash_on_cash_return,
      comparingFactor: 0.5,
    },
    {
      name: 'Cash Conversion',
      svgName: 'cashConversion',
      previous: data[0]?.previous_cash_conversion,
      current: data[0]?.current_cash_conversion,
      comparingFactor: 10,
    },
    {
      name: 'Liquidity Ratio',
      svgName: 'currentRatio',
      previous: data[0]?.previous_liquidity_ratio,
      current: data[0]?.current_liquidity_ratio,
      comparingFactor: 0.5,
    },
    {
      name: 'Debt To Equity',
      svgName: 'debtToEquity',
      previous: data[0]?.previous_debt_to_equity,
      current: data[0]?.current_debt_to_equity,
      comparingFactor: 0.5,
    },
    {
      name: 'Internal Rate of Return',
      svgName: 'rateOfReturn',
      previous: `${Number(data[0]?.previous_internal_rate_of_return) * 100}`,
      current: `${Number(data[0]?.current_internal_rate_of_return) * 100}`,
      comparingFactor: 2,
    },
  ]

  return (
    <div className="flex gap-x-5 2xl:gap-x-10">
      {ratios.map((ratio) => (
        <Tooltip
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            },
          }}
          title={
            <div className="flex flex-col gap-y-2 text-sm">
              <span>
                Current: {ratio.current}
                {ratio.name === 'Profit Margin' || ratio.name === 'Internal Rate of Return'
                  ? '%'
                  : ''}
              </span>
              <span>
                Previous: {ratio.previous}
                {ratio.name === 'Profit Margin' || ratio.name === 'Internal Rate of Return'
                  ? '%'
                  : ''}
              </span>
            </div>
          }
        >
          <div
            key={ratio.name}
            className="w-[6.7rem] h-[240px] 2xl:h-[260px] container-shadow text-center pt-4 2xl:pt-8  pb-2 px-2.5 rounded-xl"
            style={{
              backgroundColor: getColorCode(ratio),
            }}
          >
            <div className="bg-white grid place-items-center mx-auto   w-14 h-14 text-02 rounded-full container-shadow  font-libre ">
              {Number(ratio.current ?? 0)
                .toFixed(2)
                .toString()}
              {ratio.name === 'Profit Margin' || ratio.name === 'Internal Rate of Return'
                ? '%'
                : ''}
            </div>
            <div className="h-0.5 my-2 bg-gradient-to-r from-02 via-[#DAA627] to-[#E78833]"></div>
            <p className=" text-02 ml-1 font-libre">
              {Number(ratio.previous ?? 0)
                .toFixed(2)
                .toString()}
              {ratio.name === 'Profit Margin' || ratio.name === 'Internal Rate of Return'
                ? '%'
                : ''}
            </p>
            <img src={`/assets/svgs/${ratio.svgName}.svg`} alt="" className="mx-auto my-4" />
            <p className=" text-02  text-xs font-libre  w-[95%]">{ratio.name}</p>
          </div>
        </Tooltip>
      ))}
    </div>
  )
}
