import * as React from 'react'
import { alpha, styled } from '@mui/material/styles'
import { Switch } from '@mui/material'

const ColoredSwitch = styled(Switch)(({ theme }) => ({
  color: '#ff0000',
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#7cb27b',
    '&:hover': {
      backgroundColor: alpha('#7cb27b', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#7cb27b',
  },
  '& .MuiSwitch-switchBase': {
    color: '#b7bb5d',
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    width: '40px',
    height: '15px',
    backgroundColor: '#c9cd77',
  },
}))

const label = { inputProps: { 'aria-label': 'Switch' } }

export default function CustomSwitch({ checked, onChange }) {
  return <ColoredSwitch checked={checked} onChange={onChange} {...label} defaultChecked />
}
