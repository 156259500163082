import axios from 'axios'
import { CURRENT_DEVELOPER_ORG_NAME } from 'lib/constants'
import { createContext, useContext, useEffect, useReducer } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const initialState = {
  tokenContent: null,
  profile: null,
  developer: sessionStorage.getItem(CURRENT_DEVELOPER_ORG_NAME) ?? '',
  activeMenu: localStorage.getItem('ACTIVE_MENU') ?? 'dashboard',
}

const AppContext = createContext(initialState)
const AuthActionsContext = createContext({
  logout: () => {
    return
  },
  updateDeveloper: () => {
    return
  },
  setActiveMenu: () => {
    return
  },
})

const reducer = (previousState, action) => {
  switch (action.type) {
    case 'login': {
      return { ...previousState, ...action.payload }
    }
    case 'update_developer': {
      return { ...previousState, ...action.payload }
    }
    case 'set_active_menu': {
      return { ...previousState, ...action.payload }
    }
    default: {
      throw new Error('Invalid app reducer action type')
    }
  }
}

const logout = async () => {
  axios.defaults.headers.common.Authorization = ''
  window.sessionStorage.removeItem('WEB_APP_TOKEN')
  // to support logging out from all windows
  window.sessionStorage.setItem('WEB_LOGOUT_KEY', Date.now().toString())
  window.location.replace('/')
}

export const AppProvider = ({ children }) => {
  // listen for logout event and log user out of all tabs/windows
  // useAuthChanged()
  // useAutoLogout(() => {
  //   // remember where the user was before they were logged out
  //   window.sessionStorage.setItem('WEB_REDIRECT_KEY', window.location.pathname)
  //   logout()
  // })

  const [state, dispatch] = useReducer(reducer, initialState)

  const location = useLocation()
  const token = sessionStorage.getItem('WEB_APP_TOKEN')

  // if (!token || (token && isBefore(new Date(tokenContent.exp * 1000), Date.now()))) {
  //   return <Navigate to="/login" state={{ redirect: location }} replace />
  // }

  useEffect(() => {
    async function initialize() {
      try {
        const token = window.sessionStorage.getItem('WEB_APP_TOKEN')

        if (token) {
          const tokenContent = token

          dispatch({
            type: 'login',
            payload: {
              tokenContent,
            },
          })
        } else {
          throw new Error('You are not logged in')
        }
      } catch (error) {
        logout()
      }
    }

    initialize()
  }, [])

  if (!token) {
    return <Navigate to="/" state={{ redirect: location }} replace />
  }

  const updateDeveloper = async ({ developer }) => {
    dispatch({
      type: 'update_developer',
      payload: {
        developer,
      },
    })
  }

  const setActiveMenu = async ({ activeMenu }) => {
    dispatch({
      type: 'set_active_menu',
      payload: {
        activeMenu,
      },
    })
  }

  return (
    <AuthActionsContext.Provider value={{ logout, updateDeveloper, setActiveMenu }}>
      <AppContext.Provider
        value={{
          tokenContent: state.tokenContent,
          developer: state.developer,
          activeMenu: state.activeMenu,
        }}
      >
        {state.tokenContent ? children : null}
      </AppContext.Provider>
    </AuthActionsContext.Provider>
  )
}

export const useAppContext = () => {
  const context = useContext(AppContext)

  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider')
  }
  return context
}

export const useAuthActions = () => {
  const context = useContext(AuthActionsContext)

  if (context === undefined) {
    throw new Error('useAuthActions must be used within an AuthActionsContext Provider')
  }
  return context
}

// function useAuthChanged() {
//   const location = useLocation()

//   useEffect(() => {
//     const syncLogout = (event) => {
//       if (event.key === 'WEB_LOGOUT_KEY') {
//         return <Navigate to="/" state={{ redirect: location }} />
//       }
//     }

//     window.addEventListener('storage', syncLogout)

//     return () => {
//       window.removeEventListener('storage', syncLogout)
//       window.sessionStorage.removeItem('WEB_LOGOUT_KEY')
//     }
//   })
// }

// function useAutoLogout(logout) {
//   const logoutTimerIdRef = useRef(null)

//   useEffect(() => {
//     logoutTimerIdRef.current = window.setTimeout(logout, LOGOUT_TIMEOUT)

//     const resetLogoutTimer = () => {
//       if (logoutTimerIdRef.current) {
//         window.clearTimeout(logoutTimerIdRef.current)
//       }
//       const timeOutId = window.setTimeout(logout, LOGOUT_TIMEOUT)
//       logoutTimerIdRef.current = timeOutId
//     }

//     document.addEventListener('mousemove', resetLogoutTimer)
//     document.addEventListener('touchmove', resetLogoutTimer)

//     return () => {
//       document.removeEventListener('touchmove', resetLogoutTimer)
//       document.removeEventListener('mousemove', resetLogoutTimer)
//     }
//   })
// }

export default AppProvider
