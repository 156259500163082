import { CREDIT_TERM_STAGES } from 'lib/constants'
import React from 'react'
import Tooltip from '@mui/material/Tooltip'

export default function CreditTermCoverage({ data }) {
  const creditTermCoverage = data?.map((item) => ({
    developer: item.developer,
    current_stage: item.current_credit_coverage,
    project: item.project,
    current_stage_code: CREDIT_TERM_STAGES.find(
      (stage) => stage.name === item.current_credit_coverage
    )?.code,
    last_stage: item.final_closing_stage,
    last_stage_code: CREDIT_TERM_STAGES.find((stage) => stage.name === item.final_closing_stage)
      ?.code,
  }))

  return (
    <section className="relative w-full">
      <div className="flex flex-col gap-y-4 -ml-8 absolute z-10 bottom-12">
        {creditTermCoverage.map((item) => (
          <div key={item.project}>
            <Tooltip
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14],
                      },
                    },
                  ],
                },
              }}
              title={
                <div className="flex flex-col gap-y-2 text-sm">
                  <span>Project: {item.project} </span>
                  <span>Current credit coverage: {item.current_stage} </span>
                  <span>Final closing stage: {item.last_stage} </span>
                </div>
              }
              className="grid grid-cols-[16px_auto] text-xl  w-[200px] items-center "
            >
              <div className=" grid place-items-center w-5 h-5 bg-02  text-white rounded-full container-shadow text-sm 2xl:text-base font-libre "></div>
              <div
                style={{
                  width: `${
                    CREDIT_TERM_STAGES.find((stage) => stage.code === item.last_stage_code)?.width +
                    25
                  }px`,
                }}
                className="relative  ml-0.5 bg-[#912c0d] bg-opacity-[85%]  container-shadow h-2.5"
              >
                <div
                  className="z-50 h-full"
                  style={{
                    width:
                      item.current_stage_code === 'OC'
                        ? `${32 * 7 + 60}px`
                        : `${
                            CREDIT_TERM_STAGES.find(
                              (stage) => stage.code === item.current_stage_code
                            )?.width + 25
                          }px`,
                    backgroundColor: '#5a6f41',
                  }}
                ></div>
              </div>
            </Tooltip>
          </div>
        ))}
      </div>
      <div className="flex  gap-x-0.5 z-20">
        {CREDIT_TERM_STAGES.map((stage) => (
          <div className="w-8 h-[190px]  relative " key={stage.code}>
            <Tooltip
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -200],
                      },
                    },
                  ],
                },
              }}
              title={<span className="text-sm">{stage.name} </span>}
              className="text-3xl "
            >
              <div
                className={` w-8 h-[190px]  bg-opacity-[${stage.opacity}]`}
                style={{
                  backgroundColor: stage.color,
                  opacity: stage.opacity,
                }}
              ></div>
              <p className="absolute  left-1 text-02 text-sm font-libre bottom-4 ">{stage.code}</p>
            </Tooltip>
          </div>
        ))}
      </div>
    </section>
  )
}
