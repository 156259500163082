import React from 'react'
import { IoMenu } from 'react-icons/io5'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import SideBar from './side-bar'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { MenuDropdown } from './menu-dropdown'
import { useProfile } from 'data/profile'
import { toast } from 'sonner'
import { useAppContext } from 'context'

export default function AppLayout() {
  const { activeMenu } = useAppContext()
  const {
    data: profile,
    isLoading: isLoadingProfile,
    isError: isErrorFetchingProfile,
    refetch: refetchProfile,
  } = useProfile()
  const [isSideBarOpened, toggleSideBar] = useState(false)
  const location = useLocation()

  if (isErrorFetchingProfile) {
    toast.error('Error fetching your profile')
  }

  return (
    <section className="bg-gradient-base-bg bg-cover grid-cols-1 relative bg-no-repeat  w-full h-[100vh] overflow-hidden grid ">
      <SideBar
        open={isSideBarOpened}
        profile={profile}
        isLoadingProfile={isLoadingProfile}
        refetchProfile={refetchProfile}
        close={() => toggleSideBar(!isSideBarOpened)}
      />

      <section className=" w-full ">
        <div
          className={twMerge(
            'flex  w-full py-10 px-8 2xl:px-24 justify-between',
            isSideBarOpened ? 'justify-end' : 'justify-between'
          )}
        >
          <button
            className={twMerge('h-fit z-10', isSideBarOpened && 'hidden')}
            onClick={() => toggleSideBar(!isSideBarOpened)}
          >
            <IoMenu color="#f7fff0" size={25} />
          </button>

          <div className="text-06 text-lg flex items-start gap-x-9 font-libre">
            {location.pathname.includes('app-data') ||
            location.pathname.includes('dashboard') ||
            location.pathname.includes('report') ? (
              <>
                <NavLink
                  to={`/${activeMenu}/liquidity`}
                  className={({ isActive }) =>
                    `px-3 pb-2 ${isActive ? 'border-b-2 border-06 ' : ''}`
                  }
                >
                  Liquidity
                </NavLink>
                <NavLink
                  to={`/${activeMenu}/debt`}
                  className={({ isActive }) =>
                    `px-3 pb-2 ${isActive ? 'border-b-2 border-06 ' : ''}`
                  }
                >
                  Debt
                </NavLink>
                <NavLink
                  to={`/${activeMenu}/solvency`}
                  className={({ isActive }) =>
                    `px-3 pb-2 ${isActive ? 'border-b-2 border-06 ' : ''}`
                  }
                >
                  Solvency
                </NavLink>
                <NavLink
                  to={`/${activeMenu}/capital`}
                  className={({ isActive }) =>
                    `px-3 pb-2 ${isActive ? 'border-b-2 border-06 ' : ''}`
                  }
                >
                  Capital
                </NavLink>
              </>
            ) : null}

            <MenuDropdown profile={profile} />
          </div>
        </div>
        <section className="grid place-items-center mt-2 px-4 2xl:px-24">
          <Outlet />
        </section>
      </section>
    </section>
  )
}
