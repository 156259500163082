import React from 'react'
import CashBalanceChart from './cash-balance-chart'
import FinancingChart from './financing-chart'
import MoneyMarketChart from './money-market'
import { useCashProjectionBalance, useMoneyMarket, useShortTermFinancing } from 'data/liquidity'
import { useAppContext } from 'context'
import { useDeveloperProjects } from 'data/project'
import { CircularProgress } from '@mui/material'
import { groupBy } from 'lib/utils'
import { toast } from 'sonner'

export default function Liquidity() {
  const { developer } = useAppContext()
  const cashBalances = useCashProjectionBalance({ developer: developer.split(' ')?.[0] })
  const moneyMarket = useMoneyMarket({ developer: developer.split(' ')?.[0] })
  const shortTermFinancing = useShortTermFinancing({ developer: developer.split(' ')?.[0] })

  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorFetchingProjects,
  } = useDeveloperProjects({ developer: developer.split(' ')?.[0] })

  const cashGroupedData = groupBy(cashBalances?.data ?? [], 'date')
  const termFinancingGroupedData = groupBy(shortTermFinancing?.data ?? [], 'project')

  if (isErrorFetchingProjects) {
    toast.error('Error fetching developer projects')
  }

  if (cashBalances.isError || moneyMarket.isError || shortTermFinancing.isError) {
    toast.error('An error occurred while fetching graph information')
  }

  return (
    <div className="w-[95%] bg-06 bg-opacity-80 shadow-[30px_30px_4px_0px_rgba(0,0,0,0.15)]  h-[80vh] overflow-y-auto overflow-x-auto rounded-[40px] p-4 2xl:py-8 2xl:px-16">
      <div className="w-full text-center text-2xl 2xl:text-4xl font-libre">
        <span className="bg-gradient-to-b  from-[#9f9c5d] to-[rgba(193,155,22,1)] text-transparent bg-clip-text  ">
          Liquidity{' '}
        </span>
        <span className="text-09 text-opacity-80 ">Monitor</span>
      </div>
      {isLoadingProjects ? (
        <div className=" grid place-items-center mt-52">
          <CircularProgress color="inherit" className="text-02" size={30} />
        </div>
      ) : (
        projects &&
        (projects.length ? (
          <>
            <p className="liquidity-graph-title text-02 font-semibold -mb-6 text-base 2xl:text-lg font-libre">
              Projected Cash Balances
            </p>
            <div className="grid grid-rows-[auto_auto_auto] justify-center  ">
              {cashBalances.isLoading ? (
                <div className=" mt-36 mb-20  w-full grid place-items-center">
                  <CircularProgress color="inherit" className="text-02" size={30} />
                </div>
              ) : (
                cashBalances.data &&
                (cashBalances.data.length ? (
                  <div className="grid grid-cols-[250px_250px_250px_250px] 2xl:grid-cols-[300px_300px_300px_300px] gap-x-3 -mt-5">
                    {Object.keys(cashGroupedData)
                      .slice(0, 4)
                      .map((item) => (
                        <CashBalanceChart data={cashGroupedData[item]} date={item} />
                      ))}
                  </div>
                ) : (
                  <p className=" my-32 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
                    No cash balance yet
                  </p>
                ))
              )}

              <div className="h-[5px] w-full  -my-1 bg-gradient-to-r from-[#42644c] via-[#DAA627] to-[#E78833]"></div>
              <div className="flex flex-nowrap gap-x-4 2xl:gap-x-8 justify-center -mt-5">
                {shortTermFinancing.isLoading ? (
                  <div className=" mt-36 mb-16 grid place-items-center">
                    <CircularProgress color="inherit" className="text-02" size={30} />
                  </div>
                ) : (
                  shortTermFinancing.data && (
                    <>
                      {shortTermFinancing.data.length ? (
                        Object.keys(termFinancingGroupedData).map((item) => (
                          <div className="grid grid-cols-[auto_auto]">
                            <div className="flex flex-col justify-center items-center relative">
                              <FinancingChart data={termFinancingGroupedData[item]} />
                              <p className=" text-02 font-semibold text-sm -mt-12  font-libre">
                                {item}
                              </p>
                            </div>
                            <div className=" w-1 mr-2  h-44 mt-16 bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
                          </div>
                        ))
                      ) : (
                        <p className=" my-32 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
                          No short term financing yet
                        </p>
                      )}
                    </>
                  )
                )}
                {moneyMarket.data &&
                  (moneyMarket.data.length ? (
                    <div className="text-center">
                      <MoneyMarketChart data={moneyMarket.data} />
                      <p className=" text-02 font-semibold text-sm -mt-12 font-libre">
                        Money Market
                      </p>
                    </div>
                  ) : null)}
              </div>
            </div>

            <p className="liquidity-graph-title text-02 mt-5 font-semibold text-base 2xl:text-lg  font-libre">
              Short-Term Financing
            </p>
          </>
        ) : (
          <p className=" mt-52 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
            No active project
          </p>
        ))
      )}
    </div>
  )
}
