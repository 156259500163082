import { IoChevronBackCircleOutline, IoAddCircleOutline } from 'react-icons/io5'
import React from 'react'
import { CircularProgress } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppContext } from 'context'
import { toast } from 'sonner'
import { useCapitalSources } from 'data/capital'
import { formatter } from 'lib/utils'

export default function CapitalSources() {
  const { developer } = useAppContext()
  const [searchParams] = useSearchParams()
  const projectName = searchParams.get('project').replace(/-/g, ' ')
  const navigate = useNavigate()

  const {
    data: capitalSources,
    isLoading: isLoadingSources,
    isError: isErrorFetchingSources,
  } = useCapitalSources({ developer: developer.split(' ')?.[0], project: projectName })

  if (isErrorFetchingSources) {
    toast.error('Error fetching capital sources')
  }

  const totalSourcesAmount = capitalSources?.reduce(
    (accumulator, currentValue) => accumulator + Number(currentValue.amount),
    0
  )

  return (
    <div className=" relative w-[80%] bg-06 bg-opacity-80 shadow-[30px_30px_4px_0px_rgba(0,0,0,0.15)]  min-h-[65vh] max-h-[76vh]  overflow-y-hidden overflow-x-hidden rounded-[40px] px-16 py-8 justify-center justify-items-center">
      <div className="w-full text-center flex">
        <div className="capitalButton absolute left-0 w-2/12 ">
          <button onClick={() => navigate('/client-data-form')}>
            <IoChevronBackCircleOutline size={40} color="rgba(193,155,22,1)" />
          </button>
        </div>
        <div className="capitalHeading   w-full">
          <span className="bg-gradient-to-b font-libre from-[#9f9c5d] to-[rgba(193,155,22,1)] text-2xl xl:text-3xl text-transparent bg-clip-text  ">
            {projectName} Capital Sources
          </span>
        </div>
        <div className="capitalButton absolute right-0 w-2/12 ">
          <button
            onClick={() => navigate(`/capital-sources/new?project=${searchParams.get('project')}`)}
          >
            <IoAddCircleOutline size={40} color="rgba(193,155,22,1)" />
          </button>
        </div>
      </div>
      {isLoadingSources ? (
        <div className=" grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-02" size={30} />
        </div>
      ) : (
        capitalSources &&
        (capitalSources.length ? (
          <div className=" min-h-[48vh] mt-8  max-h-[58vh] py-4 overflow-y-auto">
            <table className="w-full xl:w-[90%] text-left mx-auto xl:mr-10  ">
              <thead className="font-libre font-semibold text-02">
                <tr className="graph-title whitespace-nowrap ">
                  <th className="px-3 xl:px-6 py-3   font-semibold   tracking-wider text-02">
                    Source
                  </th>
                  <th className="px-3 xl:px-6 py-3  font-semibold   tracking-wider text-02">
                    Amount
                  </th>
                  <th className="px-3 xl:px-6 py-3  font-semibold   tracking-wider text-02">
                    First Draw Date
                  </th>
                  <th className="px-3 xl:px-6 py-3   font-semibold   tracking-wider text-02">
                    Last Draw Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {capitalSources.map((item) => (
                  <tr
                    key={item.id}
                    className="hover:bg-[#c9cd77] cursor-pointer"
                    onClick={() => navigate(`/capital-sources/${item.id}`)}
                  >
                    <td className="px-3 xl:px-6  rounded-l-lg text-02 py-3 graph-title  text-sm capitalize font-libre tracking-wider  whitespace-nowrap">
                      {item.capital_source}
                    </td>
                    <td className="px-3 xl:px-6 py-3 text-02 text-sm capitalize font-libre tracking-wider whitespace-nowrap">
                      ${formatter.format(item.amount)}
                    </td>
                    <td className="px-3 xl:px-6 py-3 text-02 text-sm capitalize font-libre tracking-wider whitespace-nowrap">
                      {item.start_date}
                    </td>
                    <td className="px-3 xl:px-6 py-3 text-02  text-sm capitalize font-libre tracking-wider whitespace-nowrap  rounded-r-lg">
                      {item.end_date}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex mt-10 pl-3 xl:pl-8 space-x-12 xl:w-[90%] mx-auto">
              <p className="  w-fit  grid place-items-center graph-title  font-libre font-semibold  text-02">
                Total Amount
              </p>
              <p className="  w-fit grid place-items-center graph-title  font-libre font-semibold  text-02">
                ${formatter.format(totalSourcesAmount)}
              </p>
            </div>
          </div>
        ) : (
          <p className=" mt-40 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
            You haven't added any capital source
          </p>
        ))
      )}
    </div>
  )
}
