import * as React from 'react'
import RevenueToComplete from './Revenue'
import CostToComplete from './Cost'
import TimeToComplete from './Time'
import { useAnnualizedRoi, useCapitalAdequacy, useScheduleFeasibility } from 'data/capital'
import { CircularProgress } from '@mui/material'
import { toast } from 'sonner'
import { useAppContext } from 'context'
import CapitalAdequacy from '../capital-adequacy'
import ScheduleFeasibility from '../schedule-feasibility'
import AnnualizedRoi from '../annualized-roi'
import { useState } from 'react'
import { useEffect } from 'react'

export default function ScenarioAnalysis() {
  const { developer } = useAppContext()
  const [capitalVariationResult, setCapitalVariationResult] = useState([])
  const [scheduleVariationResult, setScheduleVariationResult] = useState([])
  const [roiVariationResult, setRoiVariationResult] = useState([])
  const capitalAdequacy = useCapitalAdequacy({ developer: developer.split(' ')?.[0] })
  const scheduleFeasibility = useScheduleFeasibility({ developer: developer.split(' ')?.[0] })
  const annualizedRoi = useAnnualizedRoi({ developer: developer.split(' ')?.[0] })
  const [isLoadingCapital, setIsLoadingCapital] = useState(false)
  const [isLoadingSchedule, setIsLoadingSchedule] = useState(false)
  const [isLoadingRoi, setIsLoadingRoi] = useState(false)

  if (capitalAdequacy.isError || scheduleFeasibility.isError || annualizedRoi.isError) {
    toast.error('An error occurred while fetching graph information')
  }
  const [checkedCost, setCheckedCost] = useState(false)
  const [checkedRevenue, setCheckedRevenue] = useState(false)
  const [checkedTime, setCheckedTime] = useState(false)
  const [switchControl, setSwitchControl] = useState('')

  useEffect(() => {
    if (switchControl === 'cost' && checkedCost) {
      setCheckedRevenue(false)
      setCheckedTime(false)
    } else if (switchControl === 'revenue' && checkedRevenue) {
      setCheckedCost(false)
      setCheckedTime(false)
    } else if (switchControl === 'time' && checkedTime) {
      setCheckedCost(false)
      setCheckedRevenue(false)
    }
  }, [switchControl, checkedCost, checkedRevenue, checkedTime])

  return (
    <section className="-mt-2 2xl:mt-5">
      <div className="grid justify-between grid-cols-[420px_auto_250px_auto_250px_auto_240px] xl:grid-cols-[380px_auto_150px_auto_150px_auto_200px]  2xl:grid-cols-[420px_auto_250px_auto_250px_auto_240px] gap-x-2 2xl:gap-x-4">
        <div className="space-y-5 w-[450px] xl:w-[420px] 2xl:w-[450px]">
          <CostToComplete
            next={({ capitalData, roiData }) => {
              setCapitalVariationResult(capitalData)
              setRoiVariationResult(roiData)
            }}
            setIsLoadingCapital={setIsLoadingCapital}
            setIsLoadingRoi={setIsLoadingRoi}
            checked={checkedCost}
            onChange={() => {
              setCheckedCost(!checkedCost)
              setSwitchControl('cost')
            }}
          />
          <TimeToComplete
            next={({ scheduleData, roiData }) => {
              setScheduleVariationResult(scheduleData)
              setRoiVariationResult(roiData)
            }}
            setIsLoadingSchedule={setIsLoadingSchedule}
            setIsLoadingRoi={setIsLoadingRoi}
            checked={checkedTime}
            onChange={() => {
              setCheckedTime(!checkedTime)
              setSwitchControl('time')
            }}
          />
          <RevenueToComplete
            next={({ roiData }) => {
              setRoiVariationResult(roiData)
            }}
            setIsLoadingRoi={setIsLoadingRoi}
            checked={checkedRevenue}
            onChange={() => {
              setCheckedRevenue(!checkedRevenue)
              setSwitchControl('revenue')
            }}
          />
        </div>
        <div className=" w-1 h-48 mt-6 bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
        {capitalAdequacy.isLoading || isLoadingCapital ? (
          <div className=" mt-24  w-full grid place-items-center">
            <CircularProgress color="inherit" className="text-02" size={30} />
          </div>
        ) : capitalVariationResult.length ? (
          <CapitalAdequacy data={capitalVariationResult} />
        ) : capitalAdequacy.data ? (
          <CapitalAdequacy data={capitalAdequacy.data} />
        ) : null}
        <div className=" w-1 h-48 mt-6 bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
        {scheduleFeasibility.isLoading || isLoadingSchedule ? (
          <div className=" mt-24  w-full grid place-items-center">
            <CircularProgress color="inherit" className="text-02" size={30} />
          </div>
        ) : scheduleVariationResult.length ? (
          <ScheduleFeasibility data={scheduleVariationResult} />
        ) : scheduleFeasibility.data ? (
          <ScheduleFeasibility data={scheduleFeasibility.data} />
        ) : null}
        <div className=" w-1 h-48 mt-6 bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
        {annualizedRoi.isLoading || isLoadingRoi ? (
          <div className=" mt-24  w-full grid place-items-center">
            <CircularProgress color="inherit" className="text-02" size={30} />
          </div>
        ) : roiVariationResult.length ? (
          <AnnualizedRoi data={roiVariationResult} />
        ) : annualizedRoi.data ? (
          <AnnualizedRoi data={annualizedRoi.data} />
        ) : null}
      </div>

      <p className="graph-title text-02 font-semibold  text-lg mt-5 2xl:mt-10 font-libre">
        Scenario Analysis
      </p>
    </section>
  )
}
