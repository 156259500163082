import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#f9eeca',
          color: '#42644c',
          padding: '6px',
          fontFamily: 'libre',
        },
      },
    },
  },
})

export default theme
