import Button from 'components/button'
import { IoChevronBackCircleOutline } from 'react-icons/io5'
import React from 'react'
import { CircularProgress } from '@mui/material'
import { Select, TextField } from 'components/form/input'
import { Formik } from 'formik'
import yup from 'lib/yup'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppContext } from 'context'
import { toast } from 'sonner'
import { useCapitalSource } from 'data/capital'
import { editCapitalSource } from 'lib/api/capital'
import { formatAmount } from 'lib/utils'

const validationSchema = yup.object({
  capital_source: yup.string().required('Enter equity'),
  amount: yup.string().required('amount required'),
  start_date: yup.string().required('enter first draw date in the correct format'),
  end_date: yup.string().required('enter last draw date in the correct format'),
})

export default function EditCapitalSource() {
  const { id } = useParams()
  const { developer } = useAppContext()
  const navigate = useNavigate()

  const {
    data: capitalSource,
    isLoading: isLoadingSource,
    isError: isErrorFetchingSource,
    refetch: refetchSource,
  } = useCapitalSource({ developer: developer.split(' ')?.[0], id })

  if (isErrorFetchingSource) {
    toast.error('Error fetching capital source')
  }

  const formDefaultValues = {
    capital_source: capitalSource?.capital_source,
    amount: capitalSource?.amount,
    start_date: capitalSource?.start_date,
    end_date: capitalSource?.end_date,
  }

  return (
    <div className=" relative w-[80%] bg-06 bg-opacity-80 shadow-[30px_30px_4px_0px_rgba(0,0,0,0.15)]  min-h-[65vh] max-h-[76vh]  overflow-y-hidden overflow-x-hidden rounded-[40px] px-16 py-8 justify-center justify-items-center">
      {isLoadingSource ? (
        <div className=" grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-02" size={30} />
        </div>
      ) : capitalSource ? (
        <>
          <div className="w-full text-center flex">
            <div className="capitalButton absolute left-0 w-2/12 ">
              <button
                onClick={() =>
                  navigate(
                    `/capital-sources?project=${capitalSource?.project.replace(/\s+/g, '-')}`
                  )
                }
              >
                <IoChevronBackCircleOutline size={40} color="rgba(193,155,22,1)" />
              </button>
            </div>
            <div className="capitalHeading   w-full">
              <span className="bg-gradient-to-b font-libre from-[#9f9c5d] to-[rgba(193,155,22,1)] text-3xl text-transparent bg-clip-text  ">
                {capitalSource.project} Capital Sources
              </span>
            </div>
          </div>
          <div className="  mt-8">
            <Formik
              initialValues={formDefaultValues}
              onSubmit={async (values) => {
                values.amount = formatAmount(values.amount)
                try {
                  const payload = {
                    ...values,
                    developer,
                    project: capitalSource.project,
                  }
                  await editCapitalSource({
                    developerName: developer.split(' ')?.[0],
                    payload,
                    id: capitalSource?.id,
                  })
                  toast.success('Capital Sources successfully edited')
                  refetchSource()
                  navigate(
                    `/capital-sources?project=${capitalSource?.project.replace(/\s+/g, '-')}`
                  )
                } catch (error) {
                  toast.error(error.message ?? 'An error occurred while saving capital sources')
                }
              }}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <section>
                  <div className=" min-h-[38vh] max-h-[48vh]  pt-4 overflow-y-auto mt-5">
                    <form onSubmit={handleSubmit}>
                      <table className="w-full xl:w-[90%]  mx-auto">
                        <thead className="font-libre font-semibold text-02">
                          <tr className="graph-title">
                            <th className="px-6 py-3   font-semibold   tracking-wider text-02">
                              Source
                            </th>
                            <th className="px-6 py-3  font-semibold   tracking-wider text-02">
                              Amount
                            </th>
                            <th className="px-6 py-3  font-semibold   tracking-wider text-02">
                              First Draw Date
                            </th>
                            <th className="px-6 py-3   font-semibold   tracking-wider text-02">
                              Last Draw Date
                            </th>
                          </tr>
                        </thead>
                        <tbody className=" divide-y divide-gray-200 ">
                          <tr key={capitalSource.id}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <Select
                                className="w-[10rem] xl:w-[10rem] text-center 2xl:w-[12rem] px-2 xl:px-3"
                                id="capital_source"
                                name="capital_source"
                                value={values.capital_source}
                                error={touched.capital_source && !!errors.capital_source}
                                helperText={touched.capital_source ? errors.capital_source : ''}
                                onChange={handleChange}
                              >
                                <option value="" selected disabled>
                                  Select
                                </option>
                                <option value="Cash Equity">Cash Equity</option>
                                <option value="Construction Term Loan">
                                  Construction Term Loan
                                </option>
                                <option value="Shareholder Loan">Shareholder Loan</option>
                                <option value="VTB Mortgage">VTB Mortgage</option>
                                <option value="Construction Revolver">Construction Revolver</option>
                                <option value="Land Loan">Land Loan</option>
                                <option value="Pre-Construction Loan">Pre-Construction Loan</option>
                                <option value="Bridge Loan">Bridge Loan</option>
                                <option value="Long Term Debt">Long Term Debt</option>
                                <option value="Purchasers Deposits">Purchasers Deposits</option>
                                <option value="Deferred Costs">Deferred Costs</option>
                                <option value="Subordinate Loan">Subordinate Loan</option>
                                <option value="Land Equity">Land Equity</option>
                              </Select>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <TextField
                                placeholder="$2,500"
                                className="w-[10rem] xl:w-[10rem] 2xl:w-[12rem]  px-2"
                                type="text"
                                id="amount"
                                name="amount"
                                error={touched.amount && !!errors.amount}
                                helperText={touched.amount ? errors.amount : ''}
                                value={values.amount}
                                onChange={handleChange}
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <TextField
                                className="w-[10rem] xl:w-[10rem] 2xl:w-[12rem]"
                                type="date"
                                name="start_date"
                                id="start_date"
                                value={values.start_date}
                                error={touched.start_date && !!errors.start_date}
                                helperText={touched.start_date ? errors.start_date : ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <TextField
                                className="w-[10rem] xl:w-[10rem] 2xl:w-[12rem]"
                                type="date"
                                id="last_draw_date"
                                name="end_date"
                                value={values.end_date}
                                error={touched.end_date && !!errors.end_date}
                                helperText={touched.end_date ? errors.end_date : ''}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </form>
                  </div>
                  <Button
                    title="Save"
                    className="h-16 w-[120px] mx-auto"
                    isSubmitting={isSubmitting}
                    onClick={handleSubmit}
                  />
                </section>
              )}
            </Formik>
          </div>
        </>
      ) : null}
    </div>
  )
}
