import React, { useState } from 'react'
import { DeveloperDropdown } from './developer-dropdown'
import { BiLogOut } from 'react-icons/bi'
import { twMerge } from 'tailwind-merge'
import Backdrop from '@mui/material/Backdrop'
import axios from 'axios'
import { BsArrowClockwise } from 'react-icons/bs'
import { IoCloseSharp, IoCamera } from 'react-icons/io5'
import { Tooltip, CircularProgress } from '@mui/material'
import { uploadImage } from 'lib/api/client'
import { toast } from 'sonner'
import { CURRENT_DEVELOPER_ORG_NAME } from 'lib/constants'
import { useAppContext, useAuthActions } from 'context'
import { useEffect } from 'react'
import { recalculateAllFunctions } from 'lib/api/liquidity'

export default function SideBar({ close, open, profile, isLoadingProfile, refetchProfile }) {
  const { updateDeveloper } = useAuthActions()
  const { developer } = useAppContext()

  const [image, setImage] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const [recalculating, setRecalculating] = useState(false)

  useEffect(() => {
    if (profile && !profile?.payload?.developers) {
      sessionStorage.setItem(CURRENT_DEVELOPER_ORG_NAME, profile?.payload?.user?.organizationName)
      updateDeveloper({ developer: profile?.payload?.user?.organizationName })
    }
  }, [profile, updateDeveloper])

  async function handleSubmit(imageFile) {
    try {
      setSubmitting(true)
      const formData = new FormData()
      formData.append('file', imageFile)
      await uploadImage({ payload: formData })
      refetchProfile()
      toast.success(
        profile?.payload?.user?.image_base
          ? 'You have successfully changed your profile picture'
          : 'You have successfully uploaded your profile picture'
      )
    } catch (error) {
      toast.error(error?.message ?? 'An error occurred while uploading image')
    } finally {
      setSubmitting(false)
    }
  }

  const handleLogout = async () => {
    axios.defaults.headers.common.Authorization = ''
    window.sessionStorage.removeItem('WEB_APP_TOKEN')
    window.sessionStorage.removeItem('WEB_APP_REFRESH_TOKEN')
    // to support logging out from all windows
    window.sessionStorage.setItem('WEB_LOGOUT_KEY', Date.now().toString())
    window.location.replace('/')
  }

  const handleRecalculate = async () => {
    try {
      setRecalculating(true)
      await recalculateAllFunctions({
        developerName: developer.split(' ')?.[0],
      })
      toast.success('You have successfully triggered functions recalculation')
    } catch (error) {
      toast.error(
        error.message ??
          error.detail ??
          'An error occurred while triggering functions recalculation'
      )
    } finally {
      setRecalculating(false)
    }
  }

  return (
    <Backdrop sx={{ color: '#fff', zIndex: '100' }} open={open}>
      <div
        className={twMerge(
          ' h-full overflow-auto absolute left-0  bg-06 bg-opacity-85 z-50 px-3 xl:px-7 py-10 focus:outline-none w-[14rem] xl:w-[16rem] 2xl:w-[22rem]',
          open ? '' : 'hidden'
        )}
      >
        <div className="flex  w-full justify-end">
          <button onClick={close} className="outline-none">
            <IoCloseSharp color="#42644c" size={30} />
          </button>
        </div>
        {isLoadingProfile ? (
          <div className=" grid place-items-center mt-36">
            <CircularProgress color="inherit" className="text-02" size={30} />
          </div>
        ) : (
          <div className="flex flex-col pt-5 items-center">
            <Tooltip
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -14],
                      },
                    },
                  ],
                },
              }}
              title={
                <span className="text-sm">
                  {profile?.payload?.user?.image_base
                    ? 'Change profile picture'
                    : 'Upload profile picture'}
                </span>
              }
              placement="top"
              className="relative group"
            >
              <div className="relative grid place-items-center">
                <img
                  src={
                    image
                      ? image
                      : profile?.payload?.user?.image_base
                        ? `data:image/png;base64,${profile?.payload?.user?.image_base}`
                        : null
                  }
                  alt=""
                  style={{
                    background: `${image || profile?.payload?.user?.image_base ? `url('')` : `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/assets/images/default-user-image.png")`}`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                  className="w-[10rem] rounded-full h-[10rem] object-cover 2xl:w-[14rem] 2xl:h-[14rem]"
                />
                {!image && !profile?.payload?.user?.image_base && (
                  <IoCamera color="#f7fff0" size={40} className="absolute" />
                )}
              </div>
              {(profile?.payload?.user?.image_base || image) && (
                <div
                  className=" opacity-0 cursor-pointer absolute !transition-[opacity,translate] group-hover:opacity-100 top-0 grid place-items-center w-[10rem] rounded-full h-[10rem] 2xl:w-[14rem] 2xl:h-[14rem]"
                  style={{
                    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <IoCamera color="#f7fff0" size={40} className="absolute" />
                </div>
              )}
              <input
                type="file"
                name="profile-image"
                accept="image/*"
                disabled={isSubmitting}
                onChange={async (e) => {
                  if (e.target.files?.[0]) {
                    const reader = new FileReader()
                    reader.readAsDataURL(e.target.files[0])
                    reader.onload = function () {
                      setImage(reader.result)
                    }
                    handleSubmit(e.target.files[0])
                  }
                }}
                className="absolute  inset-0 opacity-0 cursor-pointer"
              />
            </Tooltip>

            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isSubmitting || recalculating}
            >
              <span className="flex flex-col items-center">
                <CircularProgress color="inherit" />

                <span className="mt-2 ">
                  {recalculating ? 'Recalculating...' : isSubmitting ? 'Uploading...' : ''}
                </span>
              </span>
            </Backdrop>

            <p className="text-xl 2xl:text-2xl text-02 font-libre py-8">
              {profile?.payload?.user?.firstName} {profile?.payload?.user?.lastName?.[0]}.
            </p>
            {profile?.payload?.developers ? (
              profile?.payload?.developers?.length ? (
                <DeveloperDropdown
                  options={profile?.payload?.developers?.map((item) => ({
                    id: item?.developer?.id,
                    label: item.developer?.organizationName,
                    value: item.developer?.organizationName.split(' ')?.[0],
                  }))}
                />
              ) : (
                <p className="text-sm text-02 font-libre">No assigned developer yet</p>
              )
            ) : (
              <div
                className="grid justify-center px-4 2xl:px-8 items-center  w-full h-12 2xl:h-14  font-libre rounded-full"
                style={{
                  backgroundImage: `url(${'/assets/images/button-gradient-bg.png'})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <span className="text-sm 2xl:text-base text-06 ">
                  {profile?.payload?.user?.organizationName}
                </span>
              </div>
            )}
            <button onClick={handleRecalculate} disabled={recalculating}>
              <BsArrowClockwise className="mt-20 h-12 w-12 " color="#42644c" />
            </button>

            <button
              onClick={handleLogout}
              className="gap-x-2 items-center absolute bottom-2 2xl:bottom-12 flex"
            >
              <BiLogOut color="#42644c" size={40} />
              <span className="text-base xl:text-xl font-libre text-02">Logout</span>
            </button>
          </div>
        )}
      </div>
    </Backdrop>
  )
}
