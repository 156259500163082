import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { IoIosArrowBack } from 'react-icons/io'
import { twMerge } from 'tailwind-merge'
import { TREASURY_SUBMENU_OPTIONS, DEVELOPER_SUBMENU_OPTIONS } from 'lib/constants'
import { Link } from 'react-router-dom'
import { useAppContext, useAuthActions } from 'context'
import ReportsDropDown from 'components/reports'

export const MenuDropdown = ({ profile }) => {
  const { setActiveMenu } = useAuthActions()
  const { activeMenu } = useAppContext()

  return (
    profile && (
      <Dropdown.Root>
        <Dropdown.Trigger className="focus:outline-none">
          <img src="/assets/svgs/menuIcon.svg" alt="" className="ml-8" />
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content
            className={twMerge(
              'z-[100] data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
              'min-w-[18rem] rounded-xl border border-bca-grey-2 bg-12 px-5 py-4 shadow-[0px_7px_7px_-2px_#10182808]',
              ' text-02 mt-2 font-libre will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade'
            )}
            align="end"
          >
            <Dropdown.Group className="px-4 divide-y-[2px] divide-02">
              {(profile?.payload?.developers?.length
                ? TREASURY_SUBMENU_OPTIONS
                : DEVELOPER_SUBMENU_OPTIONS
              ).map(({ label, value, url }) =>
                value === 'report' ? (
                  <ReportsDropDown />
                ) : (
                  <Link
                    className="flex gap-x-2 items-center cursor-pointer  text-center px-10 py-3 font-libre text-sm outline-none"
                    key={value}
                    to={url}
                    onClick={() => setActiveMenu({ activeMenu: value })}
                  >
                    <span className={activeMenu === value ? 'visible' : 'invisible'}>
                      <IoIosArrowBack color="#42644c" size={25} />
                    </span>
                    <p>{label}</p>
                  </Link>
                )
              )}
            </Dropdown.Group>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
    )
  )
}
