import ReactEcharts from 'echarts-for-react'
import { format } from 'date-fns'
import { groupBy, formatter } from 'lib/utils'

export default function StackedAreaChart({ data }) {
  const cashFlowGroupedMonthly = groupBy(data ?? [], 'month')
  const cashFlowGroupedProject = groupBy(data ?? [], 'project')

  const colorPalette = ['#f4d151', '#8fbd90', '#eb8761', '#d8e5bd']
  const option = {
    tooltip: {
      borderColor: '#f9eeca',
      backgroundColor: '#f9eeca',
      textStyle: {
        color: '#42644c ',
        fontFamily: 'libre',
      },
      trigger: 'axis',
      formatter: (params) => {
        return `${params?.[0]?.name.slice(0, 4) + 20 + params?.[0]?.name.slice(4)}
       ${params?.[0] ? '<br />' : ''}${params?.[0]?.seriesName ?? ''}${params?.[0]?.value ? `: ${params?.[0]?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[0]?.value))}` : ''}
       ${params?.[1] ? '<br />' : ''}${params?.[1]?.seriesName ?? ''}${params?.[1]?.value ? `: ${params?.[1]?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[1]?.value))}` : ''}
       ${params?.[2] ? '<br />' : ''}${params?.[2]?.seriesName ?? ''}${params?.[2]?.value ? `: ${params?.[2]?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[2]?.value))}` : ''}
       ${params?.[3] ? '<br />' : ''}${params?.[3]?.seriesName ?? ''}${params?.[3]?.value ? `: ${params?.[3]?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[3]?.value))}` : ''}
       ${params?.[4] ? '<br />' : ''}${params?.[4]?.seriesName ?? ''}${params?.[4]?.value ? `: ${params?.[4]?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[4]?.value))}` : ''}
       ${params?.[5] ? '<br />' : ''}${params?.[5]?.seriesName ?? ''}${params?.[5]?.value ? `: ${params?.[5]?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[5]?.value))}` : ''}
       ${params?.[6] ? '<br />' : ''}${params?.[6]?.seriesName ?? ''}${params?.[6]?.value ? `: ${params?.[6]?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[6]?.value))}` : ''}
       ${params?.[7] ? '<br />' : ''}${params?.[7]?.seriesName ?? ''}${params?.[7]?.value ? `: ${params?.[7]?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[7]?.value))}` : ''}
       ${params?.[8] ? '<br />' : ''}${params?.[8]?.seriesName ?? ''}${params?.[8]?.value ? `: ${params?.[8]?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[8]?.value))}` : ''}
       ${params?.[9] ? '<br />' : ''}${params?.[9]?.seriesName ?? ''}${params?.[9]?.value ? `: ${params?.[9]?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[9]?.value))}` : ''}
       ${params?.[10] ? '<br />' : ''} ${params?.[10]?.seriesName ?? ''}${params?.[10]?.value ? `: ${params?.[10]?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[10]?.value))}` : ''}`
      },
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: Object.keys(cashFlowGroupedMonthly).map((item) => item),
    },

    yAxis: [
      {
        type: 'value',
        splitNumber: 300,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#42644cb3',
            width: 3,
            cap: 'butt',
          },
        },

        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],

    xAxis: [
      {
        type: 'category',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#42644cb3',
            width: 3,
            cap: 'butt',
          },
        },
        boundaryGap: false,
        onZero: false,
        axisLabel: {
          show: true,
          color: '#42644c',
          fontSize: 12,
          margin: 20,
          fontFamily: 'libre',
        },
        data: Object.keys(cashFlowGroupedMonthly).map((item) => format(new Date(item), 'MMM yy')),
        axisTick: {
          show: true,
          alignWithLabel: true,
          length: 10,
          lineStyle: {
            width: 1.5,
          },
        },
      },
    ],

    series: Object.keys(cashFlowGroupedProject).map((item, index) => ({
      name: item,
      type: 'line',
      stack: 'one',
      emphasis: {
        focus: 'series',
      },
      lineStyle: {
        width: 0,
      },
      showSymbol: false,
      areaStyle: {
        color: colorPalette[index],
      },
      data: cashFlowGroupedProject[item].map((item) => item.amount),
    })),
  }

  return <ReactEcharts option={option} className="h-[1000px]" />
}
