import CustomSlider from './Slider'
import CustomSwitch from './Switch'
import { saveCostVariation } from 'lib/api/capital'
import { useAppContext } from 'context'
import { toast } from 'sonner'

export default function CostToComplete({
  setIsLoadingRoi,
  setIsLoadingCapital,
  next,
  checked,
  onChange,
}) {
  const { developer } = useAppContext()

  const handleChange = async (value) => {
    try {
      setIsLoadingCapital(true)
      setIsLoadingRoi(true)
      const response = await saveCostVariation({
        developerName: developer.split(' ')?.[0],
        payload: {
          value_change: Math.abs(value / 100),
          status: value < 0 ? 'decrement' : 'increment',
        },
      })
      next({
        capitalData: response.payload.capital_adequacy,
        roiData: response.payload.annualized_roi,
      })
    } catch (error) {
      toast.error(error.message ?? 'An error occurred while saving cost variation')
    } finally {
      setIsLoadingCapital(false)
      setIsLoadingRoi(false)
    }
  }

  return (
    <section>
      <div className="grid grid-cols-[90px_auto_auto] items-center">
        <p className="graph-title text-02 font-semibold  text-xs font-libre">Cost to complete</p>
        <CustomSlider disabled={checked === false} handleChange={(value) => handleChange(value)} />
        <CustomSwitch checked={checked} onChange={onChange} />
      </div>
    </section>
  )
}
