import React from 'react'
import LoanToValue from './loan-to-value'
import CapitalAdequacy from './capital-adequacy'
import AnnualizedRoi from './annualized-roi'
import ScheduleFeasibility from './schedule-feasibility'
import { useDeveloperProjects } from 'data/project'
import {
  useAnnualizedRoi,
  useCapitalAdequacy,
  useLoanToValue,
  useScheduleFeasibility,
} from 'data/capital'
import { useAppContext } from 'context'
import { CircularProgress } from '@mui/material'
import { toast } from 'sonner'
import ScenarioAnalysis from './scenario-analysis'

export default function CapitalAdvisory() {
  const { developer } = useAppContext()
  const loanToValue = useLoanToValue({ developer: developer.split(' ')?.[0] })
  const capitalAdequacy = useCapitalAdequacy({ developer: developer.split(' ')?.[0] })
  const scheduleFeasibility = useScheduleFeasibility({ developer: developer.split(' ')?.[0] })
  const annualizedRoi = useAnnualizedRoi({ developer: developer.split(' ')?.[0] })

  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorFetchingProjects,
  } = useDeveloperProjects({ developer: developer.split(' ')?.[0] })

  if (isErrorFetchingProjects) {
    toast.error('Error fetching developer projects')
  }

  if (
    loanToValue.isError ||
    capitalAdequacy.isError ||
    scheduleFeasibility.isError ||
    annualizedRoi.isError
  ) {
    toast.error('An error occurred while fetching graph information')
  }

  return (
    <div className="w-[100%] bg-06 bg-opacity-80 overflow-y-auto shadow-[30px_30px_4px_0px_rgba(0,0,0,0.15)]  h-[80vh]  rounded-[40px] p-4 2xl:p-8">
      <div className="w-full text-center font-libre text-2xl 2xl:text-4xl">
        <span className="bg-gradient-to-b  from-[#908d54] to-[rgba(193,155,22,1)]  text-transparent bg-clip-text  ">
          Capital
        </span>
        <span className="text-09 text-opacity-80 "> Advisory</span>
      </div>
      {isLoadingProjects || capitalAdequacy.isLoading ? (
        <div className=" grid place-items-center mt-52">
          <CircularProgress color="inherit" className="text-02" size={30} />
        </div>
      ) : (
        projects &&
        (projects.length ? (
          <>
            {capitalAdequacy?.data?.length ? (
              <>
                <p className="graph-title text-02 font-semibold  text-lg font-libre">
                  Loan to Value
                </p>
                <div className="grid grid-rows-[auto_auto_auto]">
                  <div className="mt-2 grid justify-between grid-cols-[420px_auto_250px_auto_250px_auto_240px] xl:grid-cols-[380px_auto_150px_auto_150px_auto_200px] 2xl:grid-cols-[420px_auto_250px_auto_250px_auto_240px] gap-x-4">
                    {loanToValue.isLoading ? (
                      <div className=" mt-36 mb-20  w-full grid place-items-center">
                        <CircularProgress color="inherit" className="text-02" size={30} />
                      </div>
                    ) : (
                      loanToValue.data && <LoanToValue data={loanToValue.data} />
                    )}

                    <div className=" w-1 h-48 mt-2 2xl:mt-10 bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
                    <CapitalAdequacy data={capitalAdequacy.data} />

                    <div className=" w-1 h-48 mt-2 2xl:mt-10 bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
                    {scheduleFeasibility.isLoading ? (
                      <div className=" mt-24  w-full grid place-items-center">
                        <CircularProgress color="inherit" className="text-02" size={30} />
                      </div>
                    ) : (
                      scheduleFeasibility.data && (
                        <ScheduleFeasibility data={scheduleFeasibility.data} />
                      )
                    )}
                    <div className=" w-1 h-48 mt-2 2xl:mt-10 bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
                    {annualizedRoi.isLoading ? (
                      <div className=" mt-24  w-full grid place-items-center">
                        <CircularProgress color="inherit" className="text-02" size={30} />
                      </div>
                    ) : (
                      annualizedRoi.data && <AnnualizedRoi data={annualizedRoi.data} />
                    )}
                  </div>
                  <div className="flex w-full items-center my-6 2xl:my-4">
                    <div className="h-[5px] w-[65%] 2xl:w-[58%]  bg-gradient-to-r from-02 via-[#DAA627] to-[#E78833]"></div>
                    <p className="text-02 font-libre px-4 text-sm whitespace-nowrap 2xl:text-base">
                      Capital Adequacy
                    </p>
                    <div className="h-[5px] w-[15%] 2xl:w-[15%]  bg-gradient-to-r from-02 via-[#DAA627] to-[#E78833]"></div>
                    <p className="text-02 font-libre px-4 whitespace-nowrap text-sm 2xl:text-base">
                      Schedule Feasibility
                    </p>
                    <div className="h-[5px] w-[17%]  bg-gradient-to-r from-02 via-[#DAA627] to-[#E78833]"></div>
                    <p className="text-02 font-libre px-4 text-sm whitespace-nowrap 2xl:text-base">
                      Annualized ROI
                    </p>
                  </div>
                  <ScenarioAnalysis />
                </div>
              </>
            ) : (
              <p className=" mt-52 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
                No capital adequacy yet
              </p>
            )}
          </>
        ) : (
          <p className=" mt-52 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
            No active project
          </p>
        ))
      )}
    </div>
  )
}
