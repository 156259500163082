import { CircularProgress } from '@mui/material'
import axios from 'axios'
import { Input } from 'components/form/input'
import { jwtDecode } from 'jwt-decode'
import { login } from 'lib/api/auth'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Login() {
  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleLogin = async () => {
    setIsSubmitting(true)
    try {
      setLoginError('')
      const { access_token: token, refresh_token } = await login({ username, password })
      const tokenContent = token ? jwtDecode(token) : null
      if (tokenContent?.role === 'admin') {
        throw new Error('You cannot login with an admin account')
      }
      sessionStorage.setItem('WEB_APP_TOKEN', token)
      sessionStorage.setItem('WEB_APP_REFRESH_TOKEN', refresh_token)
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      localStorage.setItem('ACTIVE_MENU', 'dashboard')
      navigate('/dashboard/liquidity')
    } catch (error) {
      setLoginError(error.message ?? 'An error occurred')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="h-[100vh] flex flex-col bg-[#42644c] items-center justify-center w-full bg-gradient-base-bg bg-cover bg-no-repeat bg-center">
      <h1 className="text-10 font-libre text-opacity-90 text-5xl">TreasuCRE</h1>
      <div className="w-[31.25rem] backdrop-brightness-[0.969] bg-transparent flex flex-col space-y-8 pb-10 justify-end h-[25rem] mt-12 border-opacity-10 rounded-[4.67rem] shadow-[30px_30px_4px_0px_rgba(0,0,0,0.1)]">
        <Input
          onChange={(e) => {
            setUsername(e.target.value)
          }}
          className="w-3/5 mx-auto h-[3.5rem] bg-11  placeholder:text-opacity-60 font-libre italic  text-08 placeholder:text-08"
          placeholder="Username"
          value={username}
        />
        <div>
          <Input
            className="w-3/5 mx-auto h-[3.5rem] bg-11  placeholder:text-opacity-60 font-libre italic  text-08 placeholder:text-08"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            value={password}
          />
          <p className="text-red-700 text-center mt-2">{loginError}</p>
        </div>

        <button
          onClick={() => handleLogin()}
          disabled={isSubmitting || !username || !password}
          className="rounded-2xl w-[117px] h-14 mx-auto bg-01 py-3 px-5 grid place-items-center"
        >
          <span className="bg-gradient-to-b font-libre from-[rgba(255,255,255,0.6)] to-[rgba(193,155,22,1)] text-xl text-transparent bg-clip-text  ">
            {isSubmitting ? (
              <>
                <CircularProgress color="inherit" className="text-white" size={22} />
                <span className="sr-only">Submitting</span>
              </>
            ) : (
              ' LOGIN'
            )}
          </span>
        </button>
      </div>
    </div>
  )
}
