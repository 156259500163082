import React from 'react'
import { twMerge } from 'tailwind-merge'
import PieChart from './pie-chart'
import CreditExposureBarChart from './bar-chart/credit-exposure'
import StackedBarChart from './bar-chart/stacked-chart'
import CreditTermCoverage from './credit-term-coverage'
import MileStoneTracker from './milestone-tracker'
import {
  useCreditExposureCredits,
  useCreditExposureInterests,
  useCreditExposureLenders,
  useCreditTermCoverage,
  useExpectedFinancing,
  useLoanToValue,
  useMilestoneTracker,
  useUpcomingMaturities,
} from 'data/debt'
import { useAppContext } from 'context'
import { CircularProgress } from '@mui/material'
import { groupBy } from 'lib/utils'
import { toast } from 'sonner'
import { useDeveloperProjects } from 'data/project'

export default function Debt() {
  const { developer } = useAppContext()
  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorFetchingProjects,
  } = useDeveloperProjects({ developer: developer.split(' ')?.[0] })

  const creditExposureLenders = useCreditExposureLenders({ developer: developer.split(' ')?.[0] })
  const creditExposureCredits = useCreditExposureCredits({ developer: developer.split(' ')?.[0] })
  const creditExposureInterests = useCreditExposureInterests({
    developer: developer.split(' ')?.[0],
  })
  const upcomingMaturities = useUpcomingMaturities({
    developer: developer.split(' ')?.[0],
  })
  const loanToValue = useLoanToValue({
    developer: developer.split(' ')?.[0],
  })
  const expectedFinancing = useExpectedFinancing({
    developer: developer.split(' ')?.[0],
  })
  const creditTermCoverage = useCreditTermCoverage({
    developer: developer.split(' ')?.[0],
  })

  const milestoneTracker = useMilestoneTracker({
    developer: developer.split(' ')?.[0],
  })

  if (isErrorFetchingProjects) {
    toast.error('Error fetching developer projects')
  }

  if (
    creditExposureInterests.isError ||
    creditExposureLenders.isError ||
    creditExposureCredits.isError ||
    upcomingMaturities.isError ||
    loanToValue.isError ||
    expectedFinancing.isError ||
    creditTermCoverage.isError ||
    milestoneTracker.isError
  ) {
    toast.error('An error occurred while fetching graph information')
  }

  const wair = {
    current: (Number(loanToValue?.data?.[0]?.current_wair ?? 0) * 100).toFixed(2),
    target: (Number(loanToValue?.data?.[0]?.target_wair ?? 0) * 100).toFixed(2),
  }

  const ltv = {
    current: (Number(loanToValue?.data?.[0]?.current_loan_to_value ?? 0) * 100).toFixed(2),
    target: (Number(loanToValue?.data?.[0]?.target_loan_to_value ?? 0) * 100).toFixed(2),
  }

  const groupedData = groupBy(upcomingMaturities?.data ?? [], 'maturity_period')

  const financingGroupedData = groupBy(expectedFinancing?.data ?? [], 'financing_period')

  return (
    <div className="w-[95%] bg-06 bg-opacity-80 overflow-y-auto shadow-[30px_30px_4px_0px_rgba(0,0,0,0.15)]  h-[80vh]  rounded-[40px] p-4 2xl:py-8 2xl:px-16">
      <div className="w-full text-center text-2xl 2xl:text-4xl font-libre">
        <span className="bg-gradient-to-b from-[#9f9c5d] to-[rgba(193,155,22,1)] text-transparent bg-clip-text  ">
          Debt A
        </span>
        <span className="text-09 text-opacity-80 ">nalytics</span>
      </div>
      {isLoadingProjects || creditExposureLenders.isLoading ? (
        <div className=" grid place-items-center mt-52">
          <CircularProgress color="inherit" className="text-02" size={30} />
        </div>
      ) : (
        projects &&
        (projects.length ? (
          <>
            {creditExposureLenders?.data?.length ? (
              <>
                <div className="w-full flex justify-between my-2 2xl:my-4">
                  <div className="flex items-start gap-x-2">
                    <div className="flex items-start">
                      <img
                        src={`/assets/svgs/${wair.current > wair.target ? 'arrowDown' : 'arrowUp'}.svg`}
                        alt=""
                        className={wair.current > wair.target ? 'mt-2' : 'mt-0'}
                      />
                      <div className="flex flex-col items-center gap-y-4">
                        <p
                          className={twMerge(
                            'font-libre text-2xl target-value',
                            wair.current > wair.target ? 'text-13' : 'text-14'
                          )}
                        >
                          {wair.current}%
                        </p>
                        <p className="text-02 text-sm font-libre">Current WAIR</p>
                      </div>
                    </div>
                    <div className="h-9 w-[2.5px] bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
                    <div className="flex flex-col items-center gap-y-4">
                      <p className="font-libre text-2xl text-14 target-value">{wair.target}%</p>
                      <p className="text-02 text-sm font-libre">Target WAIR</p>
                    </div>
                  </div>
                  <div className="flex items-start gap-x-4">
                    <div className="flex flex-col gap-y-4 items-start">
                      <div className="grid grid-cols-[auto_auto] gap-y-4 gap-x-2">
                        <img
                          src={`/assets/svgs/${ltv.current < ltv.target ? 'arrowUp' : 'arrowDown'}.svg`}
                          alt=""
                          className={ltv.current < ltv.target ? 'mt-0' : 'mt-2'}
                        />
                        <p
                          className={twMerge(
                            'font-libre text-2xl target-value',
                            ltv.current < ltv.target ? 'text-14 ml-1' : 'text-13 ml-3 '
                          )}
                        >
                          {ltv.current}%
                        </p>
                      </div>
                      <p className="text-02 text-sm font-libre">Current LTV</p>
                    </div>
                    <div className="h-9 w-[2.5px] bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
                    <div className="flex flex-col  gap-y-4">
                      <p className="font-libre text-2xl text-14 target-value">{ltv.target}%</p>
                      <p className="text-02 text-sm font-libre">Target LTV</p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-rows-[auto_auto_auto] ">
                  <div className="my-0 2xl:my-4">
                    <p className="graph-title text-02 font-semibold  text-base 2xl:text-lg font-libre">
                      Credit Exposure
                    </p>
                    <div className="grid grid-cols-[auto_auto_auto_auto_auto] -mt-12  2xl:-mt-10">
                      <div className="text-center">
                        <div className="grid grid-cols-2   w-[17rem] 2xl:w-[21.9rem]">
                          <PieChart
                            name="Lender Type"
                            data={creditExposureLenders.data?.map((item) => ({
                              name: item.lender_type,
                              value: item.percentage,
                              amount: item.amount,
                            }))}
                          />
                          <CreditExposureBarChart
                            name="Lender Type"
                            data={creditExposureLenders.data?.map((item) => ({
                              name: item.lender_type,
                              value: `${(Number(item.wair) * 100).toFixed(2)}`,
                            }))}
                          />
                        </div>
                        <p className="text-02 text-sm font-libre -mt-12">By Lender Type</p>
                      </div>
                      <div className=" w-1 mt-16 bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
                      {creditExposureCredits.isLoading ? (
                        <div className="grid grid-cols-2 mt-36  mb-12  pl-40  w-[17rem] 2xl:w-[21.9rem]">
                          <CircularProgress color="inherit" className="text-02" size={30} />
                        </div>
                      ) : (
                        creditExposureCredits.data && (
                          <div className="text-center">
                            <div className="grid grid-cols-2   w-[17rem] 2xl:w-[21.9rem]">
                              <PieChart
                                name="Credit Type"
                                data={creditExposureCredits.data?.map((item) => ({
                                  name: item.credit_type,
                                  value: item.percentage,
                                  amount: item.amount,
                                }))}
                              />
                              <CreditExposureBarChart
                                name="Credit Type"
                                data={creditExposureCredits.data?.map((item) => ({
                                  name: item.credit_type,
                                  value: `${(Number(item.wair) * 100).toFixed(2)}`,
                                }))}
                              />
                            </div>
                            <p className="text-02 text-sm font-libre -mt-12">By Credit Type</p>
                          </div>
                        )
                      )}

                      <div className=" w-1 mt-16 bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
                      {creditExposureInterests.isLoading ? (
                        <div className="grid grid-cols-2 mt-36 mb-12   pl-40  w-[17rem] 2xl:w-[21.9rem]">
                          <CircularProgress color="inherit" className="text-02" size={30} />
                        </div>
                      ) : (
                        creditExposureInterests.data && (
                          <div className="text-center">
                            <div className="grid grid-cols-2   w-[17rem] 2xl:w-[21.9rem]">
                              <PieChart
                                name="Interest Rate"
                                data={creditExposureInterests.data?.map((item) => ({
                                  name: item.interest_type,
                                  value: item.percentage,
                                  amount: item.amount,
                                }))}
                              />
                              <CreditExposureBarChart
                                name="Interest Rate"
                                data={creditExposureInterests.data?.map((item) => ({
                                  name: item.interest_type,
                                  value: `${(Number(item.wair) * 100).toFixed(2)}`,
                                }))}
                              />
                            </div>
                            <p className="text-02 text-sm font-libre -mt-12">Interest Rate Risk</p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="h-[5px]  2xl:-my-1 bg-gradient-to-r from-02 via-[#DAA627] to-[#E78833]"></div>
                  <div className="grid grid-cols-[auto_auto_auto_auto_auto_auto_auto] w-1/2 -mt-12 2xl:-mt-10">
                    {upcomingMaturities.isLoading ? (
                      <div className="grid grid-cols-2 mt-36  mb-12  pl-40  w-[17rem] 2xl:w-[21.9rem]">
                        <CircularProgress color="inherit" className="text-02" size={30} />
                      </div>
                    ) : (
                      groupedData && (
                        <div className="text-center">
                          <StackedBarChart data={groupedData} name="maturity" />
                          <p className="graph-title -mt-12 2xl:-mt-8 text-02 font-semibold  text-base 2xl:text-lg font-libre">
                            Upcoming Maturities
                          </p>
                        </div>
                      )
                    )}

                    <div className=" w-1 h-44 mt-16 bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
                    {expectedFinancing.isLoading ? (
                      <div className="grid grid-cols-2 mt-36  mb-12  pl-40  w-[17rem] 2xl:w-[21.9rem]">
                        <CircularProgress color="inherit" className="text-02" size={30} />
                      </div>
                    ) : (
                      financingGroupedData && (
                        <div className="text-center">
                          <StackedBarChart data={financingGroupedData} name="financing period" />
                          <p className="graph-title -mt-12 2xl:-mt-8 text-02 font-semibold  text-base 2xl:text-lg font-libre">
                            Expected Financing
                          </p>
                        </div>
                      )
                    )}

                    <div className=" w-1 h-44 mt-16 bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
                    {creditTermCoverage.isLoading ? (
                      <div className="grid grid-cols-2 mt-36  mb-12  pl-40  w-[17rem] 2xl:w-[21.9rem]">
                        <CircularProgress color="inherit" className="text-02" size={30} />
                      </div>
                    ) : (
                      creditTermCoverage.data && (
                        <div className="text-center mt-16 ml-16">
                          <CreditTermCoverage data={creditTermCoverage.data} />
                          <p className="graph-title  mt-4 text-02 font-semibold  text-base 2xl:text-lg font-libre">
                            Credit Term Coverage
                          </p>
                        </div>
                      )
                    )}

                    <div className=" w-1 h-44 ml-8 mt-16 bg-gradient-to-b from-02 via-[#DAA627] to-[#E78833]"></div>
                    {milestoneTracker.isLoading ? (
                      <div className="grid grid-cols-2 mt-36  mb-12  pl-40  w-[17rem] 2xl:w-[21.9rem]">
                        <CircularProgress color="inherit" className="text-02" size={30} />
                      </div>
                    ) : (
                      milestoneTracker.data && (
                        <div className="text-center mt-16 ml-16 mr-5">
                          <MileStoneTracker data={milestoneTracker.data} />
                          <p className="graph-title  mt-4 text-02 font-semibold  text-base 2xl:text-lg font-libre">
                            Milestone Tracker
                          </p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </>
            ) : (
              <p className=" mt-52 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
                No credit exposure yet
              </p>
            )}
          </>
        ) : (
          <p className=" mt-52 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
            No active project
          </p>
        ))
      )}
    </div>
  )
}
