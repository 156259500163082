import ReactEcharts from 'echarts-for-react'

export default function CreditExposureBarChart({ data, name }) {
  const dataset = data

  const valuesArray = Object.values(dataset).map((item) => item.value)

  // make array of bar colors, highlighting max/min
  const minVal = Math.min(...valuesArray)
  const maxVal = Math.max(...valuesArray)

  const barColor = (val) => {
    if (val < 0) {
      return val > minVal ? 'rgba(99,169,120,0.7) ' : 'rgba(66,100,76,0.7)'
    } else if (val > 0) {
      return val < maxVal / 4
        ? 'rgba(66,100,76,0.7)'
        : val < maxVal / 1.4
          ? 'rgba(66,100,76,0.7)'
          : 'rgba(234,90,52,0.7)'
    } else {
      return null // non-max and non-min values take default color of series
    }
  }

  const option = {
    tooltip: {
      trigger: 'axis',
      borderColor: '#f9eeca',
      backgroundColor: '#f9eeca',
      textStyle: {
        color: '#42644c ',
        fontFamily: 'libre',
      },
      formatter: (params) => {
        return `${name}: ${params?.[0]?.name}<br />WAIR: ${params?.[0]?.data?.value}% `
      },
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: 'category',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#42644cb3',
            width: 3,
            cap: 'butt',
          },
        },
        axisLabel: { show: false },
        axisTick: { show: false },
        data: dataset.map((item) => item.name),
      },
    ],
    series: [
      {
        type: 'bar',
        data: dataset.map((item) => ({
          value: item.value,
          itemStyle: {
            color: barColor(item.value),
          },
        })),
      },
    ],
  }

  return <ReactEcharts option={option} />
}
