import ReactEcharts from 'echarts-for-react'
import { formatter } from 'lib/utils'

export default function PieChart({ name, data }) {
  const dataset = data

  let colorPalette = ['#8fbd90', '#eb8761', '#758e72', '#f4d151', '#819175']

  const option = {
    tooltip: {
      trigger: 'item',
      borderColor: '#f9eeca',
      backgroundColor: '#f9eeca',
      textStyle: {
        color: '#42644c ',
        fontFamily: 'libre',
      },
      formatter: (params) => {
        return `${name}: ${params?.name}<br />Pecentage: ${params?.value}%<br />Amount: $${formatter.format(params?.data?.amount)} `
      },
      position: [10, 2],
    },
    series: [
      {
        name,
        type: 'pie',
        radius: ['70%', '25%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
        },
        color: colorPalette,
        labelLine: {
          show: false,
        },
        data: dataset,
      },
    ],
  }

  return <ReactEcharts option={option} />
}
