import * as React from 'react'
import { Box, Slider } from '@mui/material'
import { styled } from '@mui/material/styles'

const marks = [
  {
    value: -30,
    label: <span className="text-[#ebefce] text-[10px]">-30%</span>,
  },
  {
    value: -20,
    label: <span className="text-[#ebefce] text-[10px]">-20%</span>,
  },
  {
    value: -10,
    label: <span className="text-[#ebefce] text-[10px]">-10%</span>,
  },
  {
    value: 0,
    label: <span className="text-[#ebefce] text-[10px]">0%</span>,
  },
  {
    value: 10,
    label: <span className="text-[#ebefce] text-[10px]">10%</span>,
  },
  {
    value: 20,
    label: <span className="text-[#ebefce] text-[10px]">20%</span>,
  },
  {
    value: 30,
    label: <span className="text-[#ebefce] text-[10px]">30%</span>,
  },
]

function valuetext(value) {
  return `${value}%`
}

const PrettoSlider = styled(Slider)({
  color: '#ebefce',
  height: 4,

  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor: 'transparent',
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#ebefce',
    opacity: 1,
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#e9a17d',
    opacity: 1,
  },
  '& .MuiSlider-markLabel': {
    top: '25px',
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#e9a17d',
    border: '2px solid #e9a17d',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#8fbd90',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
})

export default function CustomSlider({ disabled, handleChange }) {
  return (
    <div className="w-fit  rounded-full h-[60px] px-4 2xl:px-5 py-0 bg-[#9ac399] border-[6px] border-[#c9cd77] ">
      <Box sx={{ width: 200 }}>
        <PrettoSlider
          disabled={disabled}
          aria-label="Custom marks"
          defaultValue={0}
          getAriaValueText={valuetext}
          step={10}
          min={-30}
          max={30}
          valueLabelDisplay="auto"
          marks={marks}
          onChange={(e) => {
            handleChange(e.target.value)
          }}
        />
      </Box>
    </div>
  )
}
