import { twMerge } from 'tailwind-merge'

export const Input = ({ label, disabled, error, ref, className, placeholder, ...rest }) => {
  return (
    <div className="relative grid grid-cols-1 gap-y-1">
      {label && (
        <label
          for={label?.toLocaleLowerCase().replace(/ /g, '_')}
          className=" text-sm font-semibold text-bca-grey-8"
        >
          {label}
        </label>
      )}
      <input
        disabled={disabled}
        className={twMerge(
          'focus-visible:shadow-bca-shadow-green h-[4rem]  rounded-full border border-bca-grey-2 px-8 text-bca-grey-9 focus:border-bca-success-6 focus:outline-none disabled:opacity-50',
          className
        )}
        placeholder={placeholder}
        ref={ref}
        {...rest}
      />

      {error && <p className=" text-xs text-red-700">{error}</p>}
    </div>
  )
}

export const TextField = ({ label, id, disabled, className, placeholder, helperText, ...rest }) => {
  return (
    <>
      {label && (
        <label className=" flex font-semibold text-02 font-libre align-baseline w-36" for={id}>
          {label}
        </label>
      )}
      <input
        disabled={disabled}
        className={twMerge(
          'focus-visible:shadow-bca-shadow-green h-10 w-[10rem] xl:w-[13rem] 2xl:w-[16rem] text-center text-sm border-4 font-libre border-[#42644C]   rounded-full  px-8 text-bca-grey-9 focus:border-bca-success-6 focus:outline-none disabled:opacity-50',
          className
        )}
        placeholder={placeholder}
        {...rest}
      />
      <p className="text-red-700 whitespace-nowrap">{helperText}</p>
    </>
  )
}

export const Select = ({ label, id, disabled, className, placeholder, helperText, ...rest }) => {
  return (
    <>
      {label && (
        <label className=" flex font-semibold text-02 font-libre align-baseline w-36" for={id}>
          {label}
        </label>
      )}
      <select
        disabled={disabled}
        className={twMerge(
          'focus-visible:shadow-bca-shadow-green h-10 w-[10rem] xl:w-[13rem] 2xl:w-[16rem] text-sm border-4 font-libre border-[#42644C]   rounded-full  px-8 text-bca-grey-9 focus:border-bca-success-6 focus:outline-none disabled:opacity-50',
          className
        )}
        placeholder={placeholder}
        {...rest}
      />
      <p className="text-red-700 whitespace-nowrap">{helperText}</p>
    </>
  )
}
