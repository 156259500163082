import React from 'react'
import { twMerge } from 'tailwind-merge'
import { IoAddCircleOutline } from 'react-icons/io5'
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthActions } from 'context'

const MainSubMenu = () => {
  const navigate = useNavigate()
  const { setActiveMenu } = useAuthActions()

  return (
    <Dropdown.Root>
      <Dropdown.Trigger className="focus:outline-none">
        <IoAddCircleOutline size={45} color="rgba(193,155,22,1)" />
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content
          className={twMerge(
            'z-[100] data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
            'min-w-[18rem] rounded-xl border border-bca-grey-2 bg-[#c9cd77] px-10 py-4 shadow-[0px_7px_7px_-2px_#10182808]',
            '  mt-2 font-libre will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade'
          )}
          align="end"
        >
          <Dropdown.Group>
            <ul className=" m-auto text-sm text-center  text-[#42644c] divide-y-[2px] divide-02 font-libre block justify-center">
              <li>
                <Link to="/project-data-form" className="block lg:inline-block px-4 py-3">
                  Add Project Data
                </Link>
              </li>
              <li>
                <Link to="/account?project=Firmwide" className="block lg:inline-block px-4 py-3">
                  Firmwide Account
                </Link>
              </li>
              <li>
                <button
                  onClick={() => {
                    setActiveMenu({ activeMenu: 'app-data' })
                    navigate('/app-data/liquidity')
                  }}
                  className="block lg:inline-block px-4 py-3"
                >
                  Add Liquidity Data
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setActiveMenu({ activeMenu: 'app-data' })
                    navigate('/app-data/debt')
                  }}
                  className="block lg:inline-block px-4 py-3 "
                >
                  Add Debt Data
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setActiveMenu({ activeMenu: 'app-data' })
                    navigate('/app-data/solvency')
                  }}
                  className="block lg:inline-block px-4 py-3"
                >
                  Add Firmwide Data
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setActiveMenu({ activeMenu: 'app-data' })
                    navigate('/app-data/capital')
                  }}
                  className="block lg:inline-block px-4 py-3"
                >
                  Add Sales and Activity Tables
                </button>
              </li>
              <li>
                <Link to="/client-onboarding-form" className="block lg:inline-block px-4 py-3">
                  Edit Onboarding Data
                </Link>
              </li>
            </ul>
          </Dropdown.Group>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  )
}
export default MainSubMenu
