import ReactEcharts from 'echarts-for-react'
import { formatter } from 'lib/utils'

const colorPalette = ['#8fbd90', '#eb8761', '#f4d151', '#819175', '#d8e5bd']

export default function StackedBarChart({ data }) {
  const items = Object.keys(data).map((item) => item)

  const q1 =
    data[items[0]]?.map((item) => ({
      amount: item.variance,
      category: item.category,
      type: item.type,
      project: item.project,
    })) ?? []
  const q2 =
    data[items[1]]?.map((item) => ({
      amount: item.variance,
      category: item.category,
      type: item.type,
      project: item.project,
    })) ?? []
  const q3 =
    data[items[2]]?.map((item) => ({
      amount: item.variance,
      category: item.category,
      type: item.type,
      project: item.project,
    })) ?? []
  const q4 =
    data[items[3]]?.map((item) => ({
      amount: item.variance,
      category: item.category,
      type: item.type,
      project: item.project,
    })) ?? []

  const option = {
    tooltip: {
      borderColor: '#f9eeca',
      backgroundColor: '#f9eeca',
      textStyle: {
        color: '#42644c ',
        fontFamily: 'libre',
      },
      trigger: 'item',
      formatter: (params) => {
        return `Project: ${params?.data?.project} <br />Category:  ${params?.data?.category} <br />  Type: ${params?.data?.type}  <br /> Amount: ${params?.value < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.value))} `
      },
      axisPointer: {
        type: 'shadow',
      },

      position: 'top',
    },

    yAxis: [
      {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    xAxis: [
      {
        type: 'category',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#42644cb3',
            width: 3,
            cap: 'butt',
          },
        },
        axisLabel: {
          show: true,
          color: '#42644c',
          fontSize: 12,
          fontFamily: 'libre',
        },
        data: Object.keys(data),

        axisTick: { show: false },
      },
    ],
    series: [
      {
        stack: 'one',
        data: [
          {
            value: q1[0]?.amount ?? 0,
            project: q1[0]?.project,
            category: q1[0]?.category,
            type: q1[0]?.type,
          },
          {
            value: q2[0]?.amount ?? 0,
            project: q2[0]?.project,
            category: q2[0]?.category,
            type: q2[0]?.type,
          },
          {
            value: q3[0]?.amount ?? 0,
            project: q3[0]?.project,
            category: q3[0]?.category,
            type: q3[0]?.type,
          },
          {
            value: q4[0]?.amount ?? 0,
            project: q4[0]?.project,
            category: q4[0]?.category,
            type: q4[0]?.type,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[0],
        },
        label: {
          show: false,
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: q1[1]?.amount ?? 0,
            project: q1[1]?.project,
            category: q1[1]?.category,
            type: q1[1]?.type,
          },
          {
            value: q2[1]?.amount ?? 0,
            project: q2[1]?.project,
            category: q2[1]?.category,
            type: q2[1]?.type,
          },
          {
            value: q3[1]?.amount ?? 0,
            project: q3[1]?.project,
            category: q3[1]?.category,
            type: q3[1]?.type,
          },
          {
            value: q4[1]?.amount ?? 0,
            project: q4[1]?.project,
            category: q4[1]?.category,
            type: q4[1]?.type,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[1],
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: q1[2]?.amount ?? 0,
            project: q1[2]?.project,
            category: q1[2]?.category,
            type: q1[2]?.type,
          },
          {
            value: q2[2]?.amount ?? 0,
            project: q2[2]?.project,
            category: q2[2]?.category,
            type: q2[2]?.type,
          },
          {
            value: q3[2]?.amount ?? 0,
            project: q3[2]?.project,
            category: q3[2]?.category,
            type: q3[2]?.type,
          },
          {
            value: q4[2]?.amount ?? 0,
            project: q4[2]?.project,
            category: q4[2]?.category,
            type: q4[2]?.type,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[2],
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: q1[3]?.amount ?? 0,
            project: q1[3]?.project,
            category: q1[3]?.category,
            type: q1[3]?.type,
          },
          {
            value: q2[3]?.amount ?? 0,
            project: q2[3]?.project,
            category: q2[3]?.category,
            type: q2[3]?.type,
          },
          {
            value: q3[3]?.amount ?? 0,
            project: q3[3]?.project,
            category: q3[3]?.category,
            type: q3[3]?.type,
          },
          {
            value: q4[3]?.amount ?? 0,
            project: q4[3]?.project,
            category: q4[3]?.category,
            type: q4[3]?.type,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[3],
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: q1[4]?.amount ?? 0,
            project: q1[4]?.project,
            category: q1[4]?.category,
            type: q1[4]?.type,
          },
          {
            value: q2[4]?.amount ?? 0,
            project: q2[4]?.project,
            category: q2[4]?.category,
            type: q2[4]?.type,
          },
          {
            value: q3[4]?.amount ?? 0,
            project: q3[4]?.project,
            category: q3[4]?.category,
            type: q3[4]?.type,
          },
          {
            value: q4[4]?.amount ?? 0,
            project: q4[4]?.project,
            category: q4[4]?.category,
            type: q4[4]?.type,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[4],
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: q1[5]?.amount ?? 0,
            project: q1[5]?.project,
            category: q1[5]?.category,
            type: q1[5]?.type,
          },
          {
            value: q2[5]?.amount ?? 0,
            project: q2[5]?.project,
            category: q2[5]?.category,
            type: q2[5]?.type,
          },
          {
            value: q3[5]?.amount ?? 0,
            project: q3[5]?.project,
            category: q3[5]?.category,
            type: q3[5]?.type,
          },
          {
            value: q4[5]?.amount ?? 0,
            project: q4[5]?.project,
            category: q4[5]?.category,
            type: q4[5]?.type,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[0],
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: q1[6]?.amount ?? 0,
            project: q1[6]?.project,
            category: q1[6]?.category,
            type: q1[6]?.type,
          },
          {
            value: q2[6]?.amount ?? 0,
            project: q2[6]?.project,
            category: q2[6]?.category,
            type: q2[6]?.type,
          },
          {
            value: q3[6]?.amount ?? 0,
            project: q3[6]?.project,
            category: q3[6]?.category,
            type: q3[6]?.type,
          },
          {
            value: q4[6]?.amount ?? 0,
            project: q4[6]?.project,
            category: q4[6]?.category,
            type: q4[6]?.type,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[1],
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: q1[7]?.amount ?? 0,
            project: q1[7]?.project,
            category: q1[7]?.category,
            type: q1[7]?.type,
          },
          {
            value: q2[7]?.amount ?? 0,
            project: q2[7]?.project,
            category: q2[7]?.category,
            type: q2[7]?.type,
          },
          {
            value: q3[7]?.amount ?? 0,
            project: q3[7]?.project,
            category: q3[7]?.category,
            type: q3[7]?.type,
          },
          {
            value: q4[7]?.amount ?? 0,
            project: q4[7]?.project,
            category: q4[7]?.category,
            type: q4[7]?.type,
          },
        ],
        type: 'bar',

        itemStyle: {
          color: colorPalette[2],
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: q1[8]?.amount ?? 0,
            project: q1[8]?.project,
            category: q1[8]?.category,
            type: q1[8]?.type,
          },
          {
            value: q2[8]?.amount ?? 0,
            project: q2[8]?.project,
            category: q2[8]?.category,
            type: q2[8]?.type,
          },
          {
            value: q3[8]?.amount ?? 0,
            project: q3[8]?.project,
            category: q3[8]?.category,
            type: q3[8]?.type,
          },
          {
            value: q4[8]?.amount ?? 0,
            project: q4[8]?.project,
            category: q4[8]?.category,
            type: q4[8]?.type,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[3],
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: q1[9]?.amount ?? 0,
            project: q1[9]?.project,
            category: q1[9]?.category,
            type: q1[9]?.type,
          },
          {
            value: q2[9]?.amount ?? 0,
            project: q2[9]?.project,
            category: q2[9]?.category,
            type: q2[9]?.type,
          },
          {
            value: q3[9]?.amount ?? 0,
            project: q3[9]?.project,
            category: q3[9]?.category,
            type: q3[9]?.type,
          },
          {
            value: q4[9]?.amount ?? 0,
            project: q4[9]?.project,
            category: q4[9]?.category,
            type: q4[9]?.type,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[4],
        },
      },
      {
        stack: 'one',
        data: [
          {
            value: q1[10]?.amount ?? 0,
            project: q1[10]?.project,
            category: q1[10]?.category,
            type: q1[10]?.type,
          },
          {
            value: q2[10]?.amount ?? 0,
            project: q2[10]?.project,
            category: q2[10]?.category,
            type: q2[10]?.type,
          },
          {
            value: q3[10]?.amount ?? 0,
            project: q3[10]?.project,
            category: q3[10]?.category,
            type: q3[10]?.type,
          },
          {
            value: q4[10]?.amount ?? 0,
            project: q4[10]?.project,
            category: q4[10]?.category,
            type: q4[10]?.type,
          },
        ],
        type: 'bar',
        itemStyle: {
          color: colorPalette[0],
        },
      },
    ],
  }
  return <ReactEcharts option={option} style={{ height: '300px', width: '280px' }} />
}
