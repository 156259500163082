import ReactEcharts from 'echarts-for-react'
import { getCurrentWeekDates, formatter } from 'lib/utils'

export default function FinancingChart({ data }) {
  const [start, end] = getCurrentWeekDates()

  const weekFinancingData =
    start && end
      ? data.filter(
          (item) => new Date(item.date) >= new Date(start) && new Date(item.date) < new Date(end)
        )
      : data

  const dataset = weekFinancingData.map((item) => ({
    name: item.account_type?.[0].toUpperCase() + item.account_type?.slice(1),
    date: item.date,
    account_ending: item.account_ending,
    amount: item.amount,
  }))

  const valuesArray = Object.values(dataset).map((item) => item.amount)

  // make array of bar colors, highlighting max/min
  const minVal = Math.min(...valuesArray)
  const maxVal = Math.max(...valuesArray)

  const barColor = (val) => {
    if (val < 0) {
      return val > minVal ? 'rgba(234,90,52,0.7)' : 'rgba(178,53,20,0.7)'
    } else if (val > 0) {
      return val < maxVal / 2 ? 'rgba(99,169,120,0.7)' : 'rgba(66,100,76,0.7)'
    } else {
      return null // non-max and non-min values take default color of series
    }
  }

  const option = {
    tooltip: {
      backgroundColor: '#f9eeca',
      textStyle: {
        color: '#42644c ',
        fontFamily: 'libre',
      },
      trigger: 'axis',
      formatter: (params) => {
        return `Date: ${params?.[0]?.data?.date} <br /> Account type: ${params?.[0]?.name} <br /> Account ending: ${params?.[0]?.data?.account_ending}  <br /> Amount: ${params?.[0]?.data?.amount < 0 ? '-' : ''}$${formatter.format(Math.abs(params?.[0]?.data?.amount))}`
      },
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        axisLine: {
          show: true,
          lineStyle: {
            color: '#42644c',
            width: 3,
            cap: 'butt',
          },
        },
        splitLine: {
          show: false,
        },
        data: dataset.map((item) => item.name),
        axisTick: { show: false },
        axisLabel: { show: false },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: { show: false },
        axisTick: { show: false },
      },
    ],
    series: [
      {
        type: 'bar',
        // barWidth: '30px',
        data: dataset.map((item) => ({
          value: item.amount,
          date: item.date,
          account_ending: item.account_ending,
          amount: item.amount,
          itemStyle: {
            color: barColor(item.amount),
          },
        })),
      },
    ],
  }

  return <ReactEcharts option={option} className="w-[150px] 2xl:w-[250px]" />
}
