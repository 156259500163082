import { EditFileButton, UploadFileButton } from 'components/button/data-entry'
import { React } from 'react'
import { useNavigate } from 'react-router-dom'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { BiSolidEdit } from 'react-icons/bi'
import { useAppContext } from 'context'
import { useDeveloperProjects } from 'data/project'
import { toast } from 'sonner'
import { CircularProgress } from '@mui/material'
import { useDebtCls } from 'data/debt'
import { format } from 'date-fns'

export default function DebtDataForm() {
  const navigate = useNavigate()
  const { developer: clientOrgName } = useAppContext()
  const {
    data: projects,
    isLoading: isLoadingProjects,
    isError: isErrorFetchingProjects,
  } = useDeveloperProjects({ developer: clientOrgName.split(' ')?.[0] })

  const debtCls = useDebtCls({ developer: clientOrgName.split(' ')?.[0] })

  if (isErrorFetchingProjects) {
    toast.error('Error fetching developer projects')
  }

  if (debtCls.isError) {
    toast.error('Error fetching debt commitment letters')
  }

  return (
    <div className=" relative w-[90%] bg-06 bg-opacity-80 shadow-[30px_30px_4px_0px_rgba(0,0,0,0.15)]   h-[75vh]  overflow-y-auto overflow-x-hidden rounded-[40px] px-16 py-8 justify-center justify-items-center">
      <div className="w-full text-center block">
        <div className="capitalHeading w-full text-center">
          <span className="bg-gradient-to-b font-libre from-[#9f9c5d] to-[rgba(193,155,22,1)] text-4xl text-transparent bg-clip-text  ">
            Debt
          </span>
          <span className="text-09 text-opacity-80 font-libre  text-4xl"> Data</span>
        </div>
        <div className="capitalButton my-4  flex justify-end ">
          <button onClick={() => navigate('upcoming-financings')}>
            <img src="/assets/svgs/debt-data/calendar.svg" alt="add cl data" />
          </button>
        </div>
      </div>
      {debtCls.isLoading || isLoadingProjects ? (
        <div className=" grid place-items-center mt-36">
          <CircularProgress color="inherit" className="text-02" size={30} />
        </div>
      ) : (
        projects &&
        debtCls?.data &&
        (projects.length ? (
          <div className="flex  gap-x-8 w-[100%] overflow-x-auto   xl:gap-x-12 flex-nowrap text-center">
            {projects.map((project) => (
              <div key={project.id} className="flex flex-col items-center gap-y-6">
                <label className="whitespace-nowrap graph-title w-40 font-libre font-semibold text-sm text-02">
                  {project.project_name}
                </label>

                {debtCls.data
                  ?.filter((item) => item.project === project.project_name)
                  .map((item) => (
                    <EditFileButton
                      icon={<BiSolidEdit color="white" size={22} />}
                      title={`${format(new Date(item.interest_adjustment_date), 'MM-yy')} ${item.lender}`}
                      className="w-[230px] 2xl:w-[250px] flex justify-evenly cursor-pointer"
                      onClick={() => navigate(`cl-data-form/${item.id}`)}
                    />
                  ))}
                <UploadFileButton
                  icon={<IoIosAddCircleOutline color="white" size={24} />}
                  title="Add CL Data"
                  className="w-[230px] 2xl:w-[250px] flex justify-evenly cursor-pointer"
                  onClick={() =>
                    navigate(`cl-data-form?project=${project.project_name.replace(/\s+/g, '-')}`)
                  }
                />
              </div>
            ))}
          </div>
        ) : (
          <p className=" mt-40 w-full grid place-items-center graph-title  font-libre font-semibold text-lg text-02">
            No active project
          </p>
        ))
      )}
    </div>
  )
}
