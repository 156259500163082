import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { twMerge } from 'tailwind-merge'
import { formatter } from 'lib/utils'

const getColorCode = (value) => {
  if (value > 0 && value <= 59.99) {
    return '#8fbd90'
  } else if (value >= 60 && value <= 79.99) {
    return '#f3da76'
  } else if (value >= 80) {
    return '#e9a17d'
  }
}

export default function LoanToValue({ data }) {
  const items = data?.map((item) => ({
    project: item.project,
    value: `${Math.round(Number(item.project_loan_to_value ?? 0) * 100)}%`,
    loan: item.loan,
    appraisal_value: item.appraisal_value,
  }))

  return (
    <div className="space-y-4  flex flex-col items-center justify-center  ">
      {items?.map((item) => (
        <div className="" key={item.value}>
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
            className="grid grid-cols-[56px_auto]  items-center "
            title={
              <div className="flex flex-col gap-y-2 text-sm">
                <span>{item.project} </span>
                <span>Loan: ${formatter.format(item.loan ?? 0)} </span>
                <span>Value: ${formatter.format(item.appraisal_value ?? 0)} </span>
              </div>
            }
          >
            <div
              className=" grid place-items-center w-12 h-12 text-white rounded-full container-shadow text-sm 2xl:text-base font-libre "
              style={{
                backgroundColor: getColorCode(Number(item.value.replace('%', ''))),
              }}
            >
              {item.value}
            </div>
            <div className="relative -ml-5 2xl:-ml-3 w-[300px] bg-[#d8e5bd]  container-shadow h-5 rounded-br-full rounded-tr-full">
              <div
                className={twMerge(
                  'z-50 h-full ',
                  Number(item.value.replace('%', '')) >= 96 && 'rounded-br-full rounded-tr-full'
                )}
                style={{
                  width: Number(item.value.replace('%', '')) > 100 ? '100%' : item.value,
                  backgroundColor: getColorCode(Number(item.value.replace('%', ''))),
                }}
              ></div>
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  )
}
