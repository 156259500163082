import { REPORTS_LIST } from 'lib/constants'
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { IoCheckmarkSharp } from 'react-icons/io5'
import { twMerge } from 'tailwind-merge'
import { useState } from 'react'

import { useAppContext } from 'context'

export default function ReportsDropDown() {
  const { developer } = useAppContext()
  const [downloadReportName, setDownloadReportName] = useState('')

  const handleDownload = async (url) => {
    const response = await fetch(url)
    const data = await response.blob()
    const filename = url.split('/').pop()
    const blobUrl = window.URL.createObjectURL(data)
    const aTag = document.createElement('a')
    aTag.href = blobUrl
    aTag.setAttribute('download', filename ?? '')
    // Append to html link element page
    document.body.appendChild(aTag)
    // Start download
    aTag.click()
    // Clean up and remove the link
    aTag.remove()
  }

  return (
    <Dropdown.Root>
      <Dropdown.Trigger className="focus:outline-none w-full">
        <div className="flex justify-center items-center cursor-pointer px-10  py-3 font-libre text-sm outline-none">
          Reports
        </div>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content
          className={twMerge(
            'z-[100] data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
            'min-w-[10rem] rounded-xl border border-bca-grey-2 bg-12 px-5 py-4 shadow-[0px_7px_7px_-2px_#10182808]',
            ' text-02 mt-2 font-libre will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade'
          )}
          align="start"
          alignOffset={30}
          sideOffset={-50}
          side="left"
        >
          <Dropdown.Group className="px-4 divide-y-[2px] divide-02">
            {REPORTS_LIST.map(({ label, value, url }) => (
              <button
                key={value}
                className="flex w-full  gap-x-2 items-center "
                onClick={() => {
                  setDownloadReportName(value)
                  handleDownload(`https://${developer.split(' ')?.[0]}.alphamadesimple.ca/${url}`)
                }}
              >
                <a
                  download
                  className="flex w-full  gap-x-2 items-center cursor-pointer text-center px-7 py-3 font-libre text-sm outline-none"
                  target="_blank"
                  rel="noreferrer"
                  href={`https://${developer.split(' ')?.[0]}.alphamadesimple.ca/${url}`}
                >
                  <span className={downloadReportName === value ? 'visible' : 'invisible'}>
                    <IoCheckmarkSharp color="#42644c" size={20} />
                  </span>
                  <span>{label}</span>
                </a>
              </button>
            ))}
          </Dropdown.Group>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  )
}
